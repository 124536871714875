import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ClutchWidget from '../components/ClutchWidget';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Services from '../components/Service/Services';
import OnepageMenu from '../components/OnepageMenu';
import Technologies from '../components/Features/Technologies';
import { useTranslation } from 'react-i18next';
import AgencyAction from '../components/AgencyAction';

const Service = () => {
  const { t } = useTranslation();

  return (
    <div className="body_wrapper">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area with_cta services_area"
        imgName="services-bg.jpg"
        Ptitle={t('OUR_SERVICES')}
        Pdescription={t('OUR_SERVICES_DESCRIPTION')}
        showCta
      />
      <Services />
      {/* <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0" /> */}
      <Technologies />
      {/* <ServiceSubscribe /> */}
      <ClutchWidget />
      <AgencyAction />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Service;
