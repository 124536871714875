import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import getLocalizedPath from '../shared/functions/getLocalizedPath';
import { calendlyLink } from '../shared/constants/links';

class AgencyAction extends Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <section className="action_area_three sec_pad">
        <div className="curved"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="action_content text-center">
                <h2 className="f_600 f_size_30 l_height45 mb_40">{t('INTERESTED_IN_OUR_SERVICES')}</h2>
                <a
                  href={getLocalizedPath({ i18n, path: '/contact' })}
                  className="about_btn white_btn wow fadeInLeft"
                  data-wow-delay="0.3s"
                >
                  {t('CONTACT_US')}
                </a>
                <a href={calendlyLink} className="about_btn wow fadeInRight" data-wow-delay="0.4s">
                  {t('BOOK_A_CALL')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(AgencyAction);
