import React from 'react';
import castArray from 'lodash/castArray';
import { useTranslation } from 'react-i18next';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { HashLink as Link } from 'react-router-hash-link';

const JobPositionSidebar = ({ positionData }) => {
  const { i18n } = useTranslation();
  const link = getLocalizedPath({ i18n, path: `/${castArray(positionData.link)[0]}/apply` });

  const salaryText = positionData.salary || 'Salary depending on your experience';

  return (
    <div className="job-position-sidebar sticky-top pl-lg-4 mb_50">
      <h4>{positionData.title}</h4>
      <h5>{salaryText}</h5>
      <h6 className="mt-4">Location: {positionData.cities.join(', ')}</h6>
      <Link to={link} className="apply mt-4">
        APPLY FOR THIS JOB
      </Link>
    </div>
  );
};
export default JobPositionSidebar;
