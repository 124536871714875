import React, { useEffect } from 'react';
import qs from 'qs';
import OnepageMenu from '../components/OnepageMenu';
import Contact from '../components/Contacts';
import ContactExtended from '../components/ContactExtended';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import { useLocation, useParams } from 'react-router-dom';
import { ShouldUseContactExtendedAtom } from '../recoilState';
import { useRecoilState } from 'recoil';
import ContactAiLanding from '../components/ContactAiLanding';

const About = () => {
  const { subject } = useParams();
  const location = useLocation();
  const [shouldUseContactExtended, setShouldUseContactExtended] = useRecoilState(ShouldUseContactExtendedAtom);
  const { type } = qs.parse(location.search.slice(1));

  useEffect(() => {
    if (type) {
      setShouldUseContactExtended(type);
    }
  }, [type, setShouldUseContactExtended]);

  let contactComponent = <Contact subject={subject} />;

  if (shouldUseContactExtended === 'landing') {
    contactComponent = <ContactExtended subject={subject} />;
  } else if (shouldUseContactExtended === 'ai-landing') {
    contactComponent = <ContactAiLanding subject={subject} />;
  }

  return (
    <div className="body_wrapper">
      <OnepageMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
        forceScrolledState
      />
      {contactComponent}
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default About;
