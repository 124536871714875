import React, { Component } from 'react';
import AboutWidget from './FooterWidget/AboutWidget';
import SolutionWidget from './FooterWidget/SolutionWidget';
import TeamWidget from './FooterWidget/TeamWidget';
import Reveal from 'react-reveal/Reveal';
import { HashLink as Link } from 'react-router-hash-link';

import { Form, Field, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;

const contactFormStates = {
  FORM_VISIBLE: 'FORM_VISIBLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

class FooterTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: contactFormStates.FORM_VISIBLE,
    };
  }

  handleSubmit(data) {
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      headers,
      method: 'POST',
      body: JSON.stringify(data),
    };

    fetch(`${URL}/request-contact`, params)
      .then((response) => {
        this.setState({
          ...this.state,
          formState: response.ok ? contactFormStates.SUCCESS : contactFormStates.ERROR,
        });
      })
      .catch(() => {
        this.setState({ ...this.state, formState: contactFormStates.ERROR });
      });
  }

  contactFormValidationSchema = Yup.object().shape({
    email: Yup.string().email(this.props.t('INVALID_EMAIL')),
  });

  initialValues = {
    email: '',
  };

  render() {
    const { fClass, t, i18n } = this.props;
    let FooterData = this.props.FooterData;
    return (
      <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
        <div className="footer_top">
          <div className="container">
            <div className="row">
              {FooterData.CompanyWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInUp" key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                        <Link to={getLocalizedPath({ i18n, path: '/' })} className="f-logo">
                          <img src={require('../../img/logo-polar-night-3.png')} alt="" />
                        </Link>
                        <div className="widget-wrap">
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>{t('EMAIL')}:</span>{' '}
                            <a href="mailto:contact@polarnightsoftware.com" className="f_400">
                              contact@polarnightsoftware.com
                            </a>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>{t('PHONE')} (PL):</span>{' '}
                            <a href="tel:48731407479" className="f_400">
                              (+48) 731 407 479
                            </a>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>{t('PHONE')} (FI):</span>{' '}
                            <a href="tel:358405957407" className="f_400">
                              (+358) 40 595 7407
                            </a>
                          </p>
                        </div>
                        <Formik
                          initialValues={this.initialValues}
                          onSubmit={this.handleSubmit.bind(this)}
                          validationSchema={this.contactFormValidationSchema}
                        >
                          {({ isSubmitting }) => (
                            <Form action="#" className="f_subscribe" method="post">
                              <Field
                                type="text"
                                name="email"
                                className="form-control memail"
                                placeholder={t('LEAVE_YOUR_EMAIL')}
                              />
                              <button className="btn btn-submit" type="submit" disabled={isSubmitting}>
                                <i className="ti-arrow-right"></i>
                              </button>
                              <ErrorMessage name="email">
                                {(message) => <p className="error">{message}</p>}
                              </ErrorMessage>
                            </Form>
                          )}
                        </Formik>
                        {this.state.formState === contactFormStates.SUCCESS && (
                          <div id="success">{t('WE_WILL_CONTACT_YOU_SOON')}</div>
                        )}
                        {this.state.formState === contactFormStates.ERROR && (
                          <div id="error">
                            {t('SOMETHING_WENT_WRONG')}&nbsp;
                            <span
                              className="try_again"
                              onClick={() => {
                                this.setState({
                                  ...this.state,
                                  formState: contactFormStates.FORM_VISIBLE,
                                });
                              }}
                            >
                              {t('TRY_AGAIN')}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              <AboutWidget ftitle={t('ABOUT_US')} FooterData={FooterData} />
              <SolutionWidget ftitle={t('SERVICES')} FooterData={FooterData} />
              <TeamWidget ftitle={t('OTHERS')} FooterData={FooterData} />
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-sm-6">
                <p className="mb-0 f_400">{FooterData.copywrite}</p>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-6">
                <div className="f_social_icon_two text-center">
                  {FooterData.socialIcon.map((item) => {
                    return (
                      <a href="/" key={item.id}>
                        <i className={item.icon}></i>
                      </a>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <ul className="list-unstyled f_menu text-right">
                  <li>
                    <a href="/privacy-policy">{t('PRIVACY_POLICY')}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default withTranslation()(FooterTwo);
