export const getBase64 = (file, cb) => {
  if (!file) {
    cb(null);
    return;
  }
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => cb(reader.result.split(',')[1]);
  reader.onerror = error => console.log("Error: ", error);
};
