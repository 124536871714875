import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import JobPositionSidebar from './JobPositionSidebar';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { jobPositionCategories } from './JobPositionsData';

const JobPosition = ({ positionData }) => {
  const { t, i18n } = useTranslation();

  const salaryText = positionData.salary ? (
    <>
      <strong className="f_600">{positionData.salary}</strong> per month, depending on your experience
    </>
  ) : (
    'depending on your experience'
  );

  let image = require('../../img/blog-grid/blog_single.png');
  if (positionData.category === jobPositionCategories.ux) {
    image = require('../../img/blog-grid/blog_list3.jpg');
  }

  return (
    <section className="job-position-area pt-3 pt-lg-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 blog_sidebar_left">
            <div className="blog_single mb_50">
              <Link to={getLocalizedPath({ i18n, path: '/careers#opportunities' })} className="mb_20 d-block">
                <span className="back-to">
                  <i className="ti-arrow-left"></i>
                  {t('BACK_TO_OPPORTUNITIES')}
                </span>
              </Link>
              <img className="img-fluid" src={image} alt="" />
              <div className="blog_content apply-form-content">
                <h5 className="f_p f_size_20 f_500 t_color mb-30">About us</h5>
                <p className="f_400">
                  <strong className="f_600">Polar Night Software</strong> is growing and looking for experts with a
                  vision and interest to be a part of developing a modern software consultancy company. We are a small
                  company working with Nordic and international clients and have a work culture with some Nordic
                  influence: a lot of freedom and responsibility. Our core values are:
                </p>
                <ul>
                  <li>Trust</li>
                  <li>Work-life balance</li>
                  <li>Real teamwork</li>
                </ul>
                <p className="f_400">
                  For us the feeling of a <strong className="f_600">strong team</strong> and{' '}
                  <strong className="f_600">community</strong> are important. We want to make sure our people have the
                  possibilities to develop themselves and that they always have the support of other team members,
                  whether they are in the same project or not.
                </p>
                <p className="f_400">
                  Most importantly we are looking for team players with a unique mindset - People who are committed to
                  constant improvement and to support others to grow.
                </p>
                {/*<blockquote className="blockquote mb_40">
                  <h6 className="mb-0 f_size_18 l_height30 f_p f_400">
                    Elizabeth ummm I'm telling bodge spend a penny say wellies
                    say James Bond, bubble and squeak a such a fibber you mug
                    quaint cack what.!
                  </h6>
                </blockquote>*/}
                <h5 className="f_p f_size_20 f_500 t_color mb-30 mt_30">What we expect from you</h5>
                <p className="f_400">Preferably:</p>
                <ul>
                  {positionData.expectations.map((expectation, index) => (
                    <li key={index}>{expectation}</li>
                  ))}
                </ul>
                {!!positionData.technologies.length && (
                  <>
                    <p className="f_400">
                      You master {positionData.allTechsRequired ? '' : 'some of '}
                      the following technologies and you’re willing to develop your skills further:
                    </p>
                    <ul>
                      {positionData.technologies.map((technology, index) => (
                        <li key={index}>{technology}</li>
                      ))}
                    </ul>
                  </>
                )}
                {positionData.englishRequired ? (
                  <p className="f_400">
                    Fluent English skills will be necessary for the position, cause we’re working only with Nordic and
                    international clients. It’s going to be mainly remote work and we are not going to force anyone to
                    stay long periods abroad on-site.
                  </p>
                ) : null}

                <h5 className="f_p f_size_20 f_500 t_color mb-30 mt_30">What you can expect from us</h5>
                <p className="f_400">
                  What we can offer is a great amount of freedom, responsibilities and interesting projects with
                  international customers (at the moment mainly Nordic). Your vision and ideas about developing our
                  culture, community, working methods, recruitments and business in general would be truly welcome and
                  valuable.
                </p>
                <p className="f_400">Salary: {salaryText}.</p>
                <p className="f_400">
                  We would be more than happy to tell you more about us and opportunities, so don’t hesitate to drop a
                  message! It would be great to talk more with a cup of coffee or a beer :) !
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pt-1">
            <JobPositionSidebar positionData={positionData} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default JobPosition;
