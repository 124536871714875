import React, { useState } from "react";
import { Form, Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;

const contactFormStates = {
  FORM_VISIBLE: "FORM_VISIBLE",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

const ServiceSubscribe = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState(contactFormStates.FORM_VISIBLE);

  const handleSubmit = data => {
    const headers = {
      "Content-Type": "application/json"
    };

    const params = {
      headers,
      method: "POST",
      body: JSON.stringify(data)
    };

    fetch(`${URL}/request-contact`, params)
      .then(response => {
        setFormState(
          response.ok ? contactFormStates.SUCCESS : contactFormStates.ERROR
        );
      })
      .catch(() => {
        setFormState(contactFormStates.ERROR);
      });
  };

  const contactFormValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("INVALID_EMAIL"))
  });

  const initialValues = {
    email: ""
  };

  return (
    <div className="container">
      <div className="subscribe_form_info s_form_info_two text-center">
        <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">
          {t("LEAVE_YOUR_EMAIL_WE_WILL_CONTACT_YOU")}
        </h2>
        {formState === contactFormStates.FORM_VISIBLE && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={contactFormValidationSchema}
          >
            {({ isSubmitting }) => (
              <Form className="subscribe-form">
                <Field
                  id="email"
                  name="email"
                  type="text"
                  className="form-control"
                  placeholder={t("YOUR_EMAIL")}
                />
                <ErrorMessage name="email">
                  {message => <p className="error">{message}</p>}
                </ErrorMessage>
                <button
                  type="submit"
                  className="btn_hover btn_four mt_40"
                  disabled={isSubmitting}
                >
                  {t("REQUEST_CONTACT")}
                </button>
              </Form>
            )}
          </Formik>
        )}
        {formState === contactFormStates.SUCCESS && (
          <div id="success">{t("WE_WILL_CONTACT_YOU_SOON")}</div>
        )}
        {formState === contactFormStates.ERROR && (
          <div id="error">
            {t("SOMETHING_WENT_WRONG")}&nbsp;
            <span
              className="try_again"
              onClick={() => setFormState(contactFormStates.FORM_VISIBLE)}
            >
              {t("TRY_AGAIN")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default ServiceSubscribe;
