import React from 'react';

import OnepageMenu from '../components/OnepageMenu';
import AppBanner from '../components/Banner/AppBanner';
import Service from '../components/Service/Service';
import MarketingTestimonial from '../components/Testimonial/MarketingTestimonial';
import Portfolios from '../components/Portfolios/Portfolio2grid';
import AppTeam from '../components/Team/AppTeam';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import AgencyAction from '../components/AgencyAction';

const Landing = () => {
  return (
    <div className="body_wrapper">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <AppBanner />
      {/*<Sservice/>*/}
      <Service />
      <Portfolios />
      <AppTeam />
      {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
      {/*<Paymentprocess/>*/}
      <MarketingTestimonial />
      {/*<AppTestimonial/>*/}
      <AgencyAction />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Landing;
