import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal/';
import { Link } from 'react-scroll';
import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { calendlyLink } from '../shared/constants/links';
import getLocalizedPath from '../shared/functions/getLocalizedPath';

class Breadcrumb extends Component {
  render() {
    const {
      Ptitle,
      Pdescription,
      Pdescription2,
      breadcrumbClass,
      descriptionSize,
      imgName,
      scrollDown,
      showCta,
      t,
      i18n,
    } = this.props;
    return (
      <section className={`${breadcrumbClass}`}>
        <div className="overlay"></div>
        <img className="breadcrumb_shap" src={require('../img/' + imgName)} alt="" />
        <div className="container">
          <div className="breadcrumb_content text-center">
            <Reveal effect="fadeInUp">
              <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">{Ptitle}</h1>
            </Reveal>
            <p className={`f_400 w_color l_height26 ${descriptionSize || 'f_size_16'}`}>{Pdescription}</p>
            {Pdescription2 && (
              <p className={`f_400 w_color l_height26 ${descriptionSize || 'f_size_16'}`}>{Pdescription2}</p>
            )}
            {scrollDown && (
              <div className="d-block text-center">
                <Link
                  className="d-inline-flex flex-column nav-link"
                  to={scrollDown.to}
                  spy={true}
                  smooth={true}
                  offset={scrollDown.offset}
                  duration={500}
                >
                  <span>{scrollDown.text}</span>
                  <i className="ti-angle-down" />
                </Link>
              </div>
            )}
            {showCta && (
              <div className="cta-links-container center mt_10 center">
                <Link
                  className="nav-link mt_20"
                  activeClass="active"
                  onClick={() => (window.location.href = getLocalizedPath({ i18n, path: '/contact' }))}
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                >
                  <span className="btn_hover app_btn wow fadeInLeft" data-wow-delay="0.5s">
                    {t('CONTACT_US')}
                  </span>
                </Link>
                <HashLink
                  className="book_call_btn wow fadeInLeft mt_20"
                  data-wow-delay="0.5s"
                  to="#"
                  spy={true}
                  smooth={true}
                  offset={0}
                  duration={500}
                  onClick={() => window.open(calendlyLink)}
                >
                  {t('BOOK_A_CALL')}
                </HashLink>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(Breadcrumb);
