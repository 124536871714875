import React from 'react';
import { Reveal } from 'react-reveal';
import { useTranslation } from 'react-i18next';

const ButtonsControl = ({ previous, next }) => {
  const { t } = useTranslation();

  return (
    <Reveal bottom>
      <div className="carousel-buttons">
        <button className="button prev" onClick={previous}>
          <i className="ti-arrow-left"></i>
          {t('PREV_PROJECT')}
        </button>
        <button className="button next" onClick={next}>
          {t('NEXT_PROJECT')}
          <i className="ti-arrow-right"></i>
        </button>
      </div>
    </Reveal>
  );
};

export default ButtonsControl;
