const portfolioItems = [
  {
    id: 0,
    link: 'renta',
    testimonialId: 12,
    imgs: ['renta.jpg'],
    title: 'Renta',
    shortDescription: 'RENTA_SHORT_DESC',
    description: 'RENTA_DESC',
    includeReference: true,
    client: 'Renta',
    clientSubtitle: 'Full-service machine and equipment rental services company',
    clientDescription: 'RENTA_CUSTOMER_DESC',
    date: '',
    website: 'https://renta.com',
    services: 'Web Development, Integrations, Cloud',
    technologies: '.NET, React, Node.js, Angular, AWS (queues, S3, lambda), MySQL, PostgreSQL, Cypress, Babylon.js',
    projectType: 'web',
  },
  {
    id: 1,
    link: 'efecte-itsm',
    testimonialId: 9,
    imgs: ['efecte-itsm.png'],
    title: 'Efecte',
    shortDescription: 'EFECTE_ITSM_SHORT_DESC',
    description: 'EFECTE_ITSM_DESC',
    includeReference: false,
    client: 'Efecte',
    clientSubtitle: 'European alternative in Service Management',
    clientDescription: 'EFECTE_CUSTOMER_DESC',
    date: '',
    website: 'https://efecte.com',
    services: 'Web Development, Integrations, Cloud',
    technologies: 'Java, Angular, React',
    projectType: 'web',
  },
  {
    id: 2,
    link: 'medixine',
    testimonialId: 10,
    imgs: ['medixine.png'],
    title: 'Medixine',
    shortDescription: 'MEDIXINE_SUITE_SHORT_DESC',
    description: 'MEDIXINE_SUITE_DESC',
    includeReference: false,
    client: 'Medixine',
    clientSubtitle: 'All-in-one Telehealth platform',
    clientDescription: 'MEDIXINE_CUSTOMER_DESC',
    date: '',
    website: 'https://medixine.com/platform/',
    services: 'Web Development, Integrations',
    technologies: '.NET, Entity Framework, React, MS SQL',
    projectType: 'web',
  },
  {
    id: 3,
    link: 'fleetguru',
    testimonialId: 11,
    imgs: ['fleetguru.jpg'],
    title: 'FleetGuru',
    shortDescription: 'FLEETGURU_SHORT_DESC',
    description: 'FLEETGURU_DESC',
    includeReference: true,
    client: 'FleetGuru OÜ',
    clientSubtitle: '',
    clientDescription: 'FLEETGURU_CUSTOMER_DESC',
    date: '',
    website: 'https://fleetguru.app/en',
    services: 'Web Development',
    projectType: 'web',
  },
  {
    id: 4,
    link: 'oulan',
    testimonialId: 8,
    imgs: ['oulan.jpg'],
    title: 'Oulan Hetitulkkaus',
    shortDescription: 'OULAN_SHORT_DESC',
    description: 'OULAN_DESC',
    includeReference: false,
    client: 'Oulan Group Oy',
    clientSubtitle: 'AI-based brokering platform for financial services',
    clientDescription: 'OULAN_CUSTOMER_DESC',
    date: '',
    website: 'https://oulan.fi',
    services: 'Mobile Application Development, UX Design, Integrations',
    projectType: 'mobile',
  },
  {
    id: 5,
    link: 'lvs-brokers',
    testimonialId: 3,
    imgs: ['lvs.jpg'],
    title: 'LVS Brokers',
    shortDescription: 'LVS_SHORT_DESC',
    description: 'LVS_DESC',
    includeReference: false,
    client: 'LVS Brokers Oy',
    clientSubtitle: 'AI-based brokering platform for financial services',
    clientDescription: 'LVS_CUSTOMER_DESC',
    date: '',
    website: 'https://lvsbrokers.com',
    services: 'Web Development, Integrations',
    projectType: 'web',
  },
  {
    id: 6,
    link: 'puhujatori',
    testimonialId: 7,
    imgs: ['puhujatori.jpg'],
    title: 'Puhujatori Oy',
    shortDescription: 'PUHUJATORI_SHORT_DESC',
    description: 'PUHUJATORI_DESC',
    includeReference: false,
    client: 'Puhujatori Oy',
    clientSubtitle: 'AI-based brokering platform for financial services',
    clientDescription: 'PUHUJATORI_CUSTOMER_DESC',
    date: '',
    website: 'https://puhujatori.fi',
    services: 'Mobile Application Development, UX Design, Integrations',
    projectType: 'mobile',
  },
  {
    id: 7,
    link: 'speys',
    testimonialId: 2,
    imgs: ['speys.jpg'],
    title: 'Speys',
    shortDescription: 'SPEYS_SHORT_DESC',
    description: 'SPEYS_DESC',
    includeReference: true,
    client: 'Speys Oy',
    clientSubtitle: '',
    clientDescription: 'SPEYS_CUSTOMER_DESC',
    date: '',
    website: 'https://speys.com',
    services: 'Web Development',
    projectType: 'web',
  },
  {
    id: 8,
    link: 'kiks-technologies',
    testimonialId: 1,
    imgs: ['kiks.jpg'],
    title: 'Kiks Marketplace',
    shortDescription: 'KIKS_SHORT_DESC',
    description: 'KIKS_DESC',
    includeReference: true,
    client: 'Kiks Technologies Oy',
    clientSubtitle: '',
    clientDescription: 'KIKS_CUSTOMER_DESC',
    date: '',
    website: 'https://kikstech.fi',
    services: 'Web Development, UX Design',
    projectType: 'web',
  },
  {
    id: 9,
    link: 'robu',
    testimonialId: 4,
    imgs: ['robu.jpg'],
    title: 'Robu',
    shortDescription: '',
    description: 'ROBU_CUSTOMER_DESC',
    includeReference: true,
    client: 'Robu Oy',
    clientSubtitle: '',
    clientDescription: 'ROBU_CUSTOMER_DESC',
    date: '',
    website: 'https://robu.fi',
    services: 'Web Development',
    projectType: 'web',
  },
  {
    id: 10,
    link: 'legal-jobs',
    testimonialId: 5,
    imgs: ['legal-jobs.jpg'],
    title: 'Legal Jobs',
    shortDescription: 'LEGAL_JOBS_SHORT_DESC',
    description: 'LEGAL_JOBS_DESC',
    includeReference: false,
    client: 'Legal Jobs',
    clientSubtitle: 'Online Legal services',
    clientDescription: '',
    date: '',
    website: 'https://legaljobs.pl',
    services: 'Web Development, UX Design',
    projectType: 'web',
  },
  {
    id: 11,
    link: 'leadsense',
    imgs: ['leadsense.jpg'],
    title: 'LeadSense',
    shortDescription: 'LEADSENSE_SHORT_DESC',
    description: 'LEADSENSE_DESC',
    includeReference: false,
    client: 'Polar Night Software',
    date: '',
    website: 'https://polarnight.it',
    services: 'AI, Web Development, Integrations, Cloud',
    technologies: 'OpenAI, Python, Django, PostgreSQL, Google Cloud Platform',
    projectType: 'ai, web',
  },
  {
    id: 12,
    link: 'conversational-intelligence-platform',
    imgs: ['conversational-intelligence-platform.png'],
    title: 'Conversational Intelligence Platform',
    shortDescription: 'HETKI_AI_SHORT_DESC',
    description: 'HETKI_AI_DESC',
    includeReference: false,
    client: 'Confidential',
    date: '',
    services: 'AI, Web Development, Integrations, Cloud',
    technologies: 'OpenAI, React, Python, Django, Google Cloud Platform',
    projectType: 'ai, web',
  },
  {
    id: 13,
    link: 'rokko',
    imgs: ['rokko.jpg'],
    title: 'Rokko',
    shortDescription: 'ROKKO_SHORT_DESC',
    description: 'ROKKO_DESC',
    includeReference: false,
    client: 'Polar Night Software',
    date: '',
    website: 'https://polarnight.it',
    services: 'AI, Integrations, Cloud',
    technologies: 'OpenAI, Slack API, Python, Google Cloud Platform',
    projectType: 'ai, web',
  },
  {
    id: 14,
    link: 'insights-analytics-suite',
    imgs: ['insights-analytics-suite.jpg'],
    title: 'Insight Analytics Suite',
    shortDescription: 'DYNAMICO_AI_SHORT_DESC',
    description: 'DYNAMICO_AI_DESC',
    includeReference: false,
    client: 'Confidential',
    date: '',
    services: 'AI, Web Development, Integrations, Cloud',
    projectType: 'ai, web',
  },
  {
    id: 15,
    link: 'valuematcher',
    imgs: ['valuematcher.jpg'],
    title: 'ValueMatcher',
    shortDescription: 'VALUEMATCHER_SHORT_DESC',
    description: 'VALUEMATCHER_DESC',
    includeReference: false,
    client: 'ValueMatcher',
    date: '',
    website: 'https://valuematcher.net',
    services: 'AI, Web Development, Integrations, Cloud',
    projectType: 'ai, web',
  },
  {
    id: 16,
    link: 'acis',
    testimonialId: 6,
    imgs: ['acis.jpg'],
    title: 'ACIS',
    shortDescription: 'ACIS_SHORT_DESC',
    description: 'ACIS_DESC',
    includeReference: false,
    client: 'SRC Factory Sp. z o.o.',
    clientSubtitle: 'Top quality software',
    clientDescription: '',
    date: '',
    website: 'https://car-identification.com',
    services: 'Web Development, Automotive, Machine Learning, Image Processing',
    projectType: 'ai, web, automotive',
  },
];

export default portfolioItems;
