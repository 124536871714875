import React, { useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ArticleComponent from '../components/Articles/Article';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import OnepageMenu from '../components/OnepageMenu';
import { useParams, useHistory } from 'react-router-dom';
import articlesData from '../shared/data/articlesData';
import authorsData from '../shared/data/authorsData';
import { useTranslation } from 'react-i18next';

const Article = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { articleLink } = useParams();
  const article = articlesData().find((article) => article.link === articleLink);

  // TODO: Instead of having separate articles items for specific languages, 
  // it would be better to have one article item with language specific properties
  useEffect(() => {
    if (i18n.language !== article.language) {
      const currentLangArticle = articlesData().find(
        ({ id, language }) => id === article.id && i18n.language === language
      );
      if (currentLangArticle) {
        history.replace(currentLangArticle.link);
      }
    }
  }, [i18n.language, article, history]);

  if (!article) {
    history.replace('/not-found');
    return null;
  }

  const { name: authorName } = authorsData.find(({ id }) => id === article.authorId);

  return (
    <div className="body_wrapper allow-sticky">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="articles-bg.jpg"
        Ptitle={article.title}
        Pdescription={`By: ${authorName}`}
      />
      <ArticleComponent article={article} />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Article;
