import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import JobPosition from "../components/Careers/JobPosition";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import OnepageMenu from "../components/OnepageMenu";
import ApplyBox from "../components/Careers/ApplyBox";

const JobPositionPage = props => {
  return (
    <div className="body_wrapper allow-sticky">
      <OnepageMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="polar-software-meeting027.jpg"
        Ptitle={props.positionData.title}
        Pdescription={props.positionData.cities.join(", ")}
        descriptionSize="f_size_20"
      />
      <JobPosition {...props} />
      <ApplyBox className="d-none d-lg-block" positionData={props.positionData} />
      <FooterTwo fClass="pt_lg_150" FooterData={FooterData} />
    </div>
  );
};
export default JobPositionPage;
