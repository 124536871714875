import React from 'react';
import OnepageMenu from '../components/OnepageMenu';
import Breadcrumb from '../components/Breadcrumb';
import Teams from '../components/Team/Team';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import AgencyAbout from '../components/About/AgencyAbout';
import ServiceData from '../components/Service/ServiceData';
import Sservice from '../components/Service/Sservice/Sservice';
import HRService from '../components/Service/HRService';
import CareersAction from '../components/CareersAction';
import { useTranslation } from 'react-i18next';
import HowWeWork from '../components/HowWeWork';
import Office from '../components/Office';

const Careers = () => {
  const { t } = useTranslation();
  return (
    <div className="body_wrapper">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area breadcrumb_area_three"
        imgName="polar-software-meeting027.jpg"
        Ptitle={t('CAREERS')}
        Pdescription={t('CAREERS_DESCRIPTION')}
        scrollDown={{
          to: 'opportunities',
          offset: 100,
          text: t('SEE_OPEN_POSITIONS'),
        }}
        showScrollDown
        scrollDownTargetId="opportunities"
      />
      <Sservice />
      <AgencyAbout ServiceData={ServiceData} />
      <HRService ServiceData={ServiceData} />
      <HowWeWork />
      <Office />
      <Teams />
      <CareersAction />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Careers;
