import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-scroll";
import { withTranslation } from "react-i18next";
class AgencyAbout extends Component {
  render() {
    const { t } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    let ServiceData = this.props.ServiceData;
    return (
      <section className="agency_about_area d-flex bg_color">
        <div className="col-lg-6 about_content_left ">
          <div className="about_content mb_30">
            <h2 className="f_size_30 f_700 l_height45 mb_20">
              {t("TEAM_GALLERY_HEADING")}
            </h2>
            <p className="f_size_15 f_400 mb_40">{t("TEAM_GALLERY_DESC")}</p>
            <Link
              className="nav-link"
              to="team"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <span className="about_btn">{t("MEET_YOUR_TEAM")}</span>
            </Link>
          </div>
        </div>
        <div className="col-lg-6 about_img">
          <Slider className="about_img_slider" {...settings}>
            <div className="item">
              <div className="about_item w45">
                <img src={require("../../img/home4/team1.jpg")} alt="" />
                {ServiceData.agtext1 && (
                  <div className="about_text">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext1}
                    </h5>
                  </div>
                )}
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team2.jpg")} alt="" />
                {ServiceData.agtext2 && (
                  <div className="about_text text_two">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext2}
                    </h5>
                  </div>
                )}
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team5.jpg")} alt="" />
                {ServiceData.agtext3 && (
                  <div className="about_text text_two">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext3}
                    </h5>
                  </div>
                )}
              </div>
            </div>
            <div className="item">
              <div className="about_item w45">
                <img src={require("../../img/home4/team6.jpg")} alt="" />
                {ServiceData.agtext1 && (
                  <div className="about_text">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext1}
                    </h5>
                  </div>
                )}
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team9.jpg")} alt="" />
                {ServiceData.agtext2 && (
                  <div className="about_text text_two">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext2}
                    </h5>
                  </div>
                )}
              </div>
              <div className="about_item w55">
                <img src={require("../../img/home4/team8.jpg")} alt="" />
                {ServiceData.agtext3 && (
                  <div className="about_text text_two">
                    <span className="br"></span>
                    <h5 className="f_size_18 l_height28 mb-0">
                      {ServiceData.agtext3}
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
export default withTranslation()(AgencyAbout);
