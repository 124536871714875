import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import Portfoliosingle from '../components/Portfolios/Portfoliosingle';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import OnepageMenu from '../components/OnepageMenu';
import { useTranslation } from 'react-i18next';
import portfolioItems from '../shared/data/portfolioItems';
import ProjectTestimonial from '../components/Testimonial/ProjectTestimonial';
import ServiceSubscribe from '../components/ServiceSubscribe';
import Carousel from '../components/Сarousel';

const About = ({ match, history }) => {
  const { portfolioLink } = useParams();
  const { t } = useTranslation();

  const index = portfolioItems.findIndex((item) => item.link === portfolioLink);

  if (index < 0) {
    history.replace('/not-found');
    return null;
  }

  const portfolioItem = portfolioItems[index];

  return (
    <div className="body_wrapper">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area with_cta"
        imgName="portfolio-bg.jpg"
        Ptitle={t('OUR_WORK')}
        Pdescription={t('OUR_WORK_DESCRIPTION')}
        showCta
      />
      <Portfoliosingle item={portfolioItem} />
      <Carousel items={portfolioItems} match={match} />
      <ProjectTestimonial portfolioItem={portfolioItem} />
      <div className="service-subscribe-wrapper">
        <ServiceSubscribe />
      </div>
      <FooterTwo fClass="pt_150" FooterData={FooterData} />
    </div>
  );
};
export default About;
