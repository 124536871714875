import React from 'react';
import ArticlesSidebar from './ArticlesSidebar';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import authorsData from '../../shared/data/authorsData';
import { Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';

const Article = ({ article }) => {
  const { t, i18n } = useTranslation();

  const { title, image, content, date, authorId, tags, link } = article;

  const parsedDate = parseISO(date);
  const day = parsedDate.getDate();
  const month = parsedDate.toLocaleString(i18n.language, { month: 'short' });

  const author = authorsData.find(({ id }) => id === authorId);

  const articleUrl = window.location.href.replace(window.location.hash, '');

  return (
    <section className="blog_area sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 blog_sidebar_left">
            <div className="blog_single mb_50">
              <Link replace to={getLocalizedPath({ i18n, path: '/articles' })} className="mb_20 d-block">
                <span id="back-to-articles" className="back-to">
                  <i className="ti-arrow-left"></i>
                  {t('BACK_TO_ARTICLES')}
                </span>
              </Link>
              <img className="img-fluid" src={require(`../../img/articles/${image}`)} alt="" />
              <div className="blog_content">
                <div className="post_date">
                  <h2>
                    {day} <span>{month}</span>
                  </h2>
                </div>
                <div className="entry_post_info">
                  <Link to={getLocalizedPath({ i18n, path: `/articles/${link}` })}>{author.name}</Link>
                </div>
                <h5 className="f_p f_size_20 f_500 t_color mb-30">{title}</h5>
                {content}
                <div className="post_share">
                  <div className="post-nam"> {t('SHARE')}: </div>
                  <div className="flex">
                    <LinkedinShareButton url={articleUrl}>
                      <i className="ti-linkedin"></i>LinkedIn
                    </LinkedinShareButton>
                    <FacebookShareButton url={articleUrl}>
                      <i className="ti-facebook"></i>Facebook
                    </FacebookShareButton>
                    <TwitterShareButton url={articleUrl}>
                      <i className="ti-twitter"></i>Twitter
                    </TwitterShareButton>
                  </div>
                </div>
                <div className="post_tag d-flex">
                  <div className="post-nam"> {t('TAGS')}: </div>
                  {tags.map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </div>
                <div className="media post_author mt_60">
                  <img
                    className="rounded-circle"
                    src={require(`../../img/authors/${author.image}`)}
                    alt={`${author.name} - ${author.title}`}
                  />
                  <div className="media-body">
                    <h5 className="f_p t_color3 f_size_18 f_500">{author.name}</h5>
                    <h6 className="f_p f_size_15 f_400 mb_20">{author.title}</h6>
                    <p>{t(author.description)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <ArticlesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Article;
