import React from 'react';
import castArray from 'lodash/castArray';
import { useTranslation } from 'react-i18next';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { HashLink as Link } from 'react-router-hash-link';

const ApplyBox = ({ className, positionData }) => {
  const { i18n } = useTranslation();
  const link = getLocalizedPath({ i18n, path: `/${castArray(positionData.link)[0]}/apply` });

  return (
    <div className={`container apply-form ${className}`}>
      <div className="subscribe_form_info s_form_info_two text-center">
        <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">
          Do you think you're the right match for this position?
        </h2>
        <div className="subscribe-form">
          <Link to={link} className="btn_hover btn_four">
            Apply now
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ApplyBox;
