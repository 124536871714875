import React, { Component } from "react";
import Stserviceitems from "./StserviceItem";
import Reveal from "react-reveal/Reveal";
import { withTranslation } from "react-i18next";
class Sservice extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            {t("OUR_CORE_VALUES")}
          </h2>
          <div className="row mb_30">
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1100}>
                <Stserviceitems
                  iShap="icon_shape1.png"
                  Sicon="ti-heart"
                  text={t("TRUST")}
                  description={t("TRUST_DESC")}
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1400}>
                <Stserviceitems
                  iShap="icon_shape2.png"
                  Sicon="ti-time"
                  text={t("WORK_LIFE_BALANCE")}
                  description={t("WORK_LIFE_BALANCE_DESC")}
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape3.png"
                  Sicon="ti-user"
                  text={t("REAL_TEAMWORK")}
                  description={t("REAL_TEAMWORK_DESC")}
                />
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(Sservice);
