import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ImageViewer from 'react-simple-image-viewer';
import testimonialsData from '../../shared/data/testimonialsData';

const Portfoliosingle = ({ item }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { testimonialId } = item;
  const testimonial = testimonialsData.find(({ id }) => id === testimonialId);
  const imagesFullUrls = item.imgs.map((img) => require(`../../img/portfolio-details/${img}`));

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <section id="portfolio-item" className="portfolio_details_area sec_pad">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="portfolio_details_info pr_50">
              <h5 className="f_700 f_p f_size_20 t_color3 mb-30">{item.title}</h5>
              <p className="f_400 f_size_15 mb-0">{t(item.description)}</p>
              {item.technologies && (
                <div className="p_category_item mb-30">
                  <h6 className="f_p f_size_15 f_400 t_color3 mt_30 l_height28">{t('MAIN_TECHNOLOGIES')}: </h6>
                  <p className="f_size_15 f_700 mb-0">{item.technologies}</p>
                </div>
              )}
              {item.includeReference && (
                <p className="f_size_15 mt_30 f_400">
                  <span className="testimonial">“{t(testimonial.description)}”</span> — {testimonial.authorPost},{' '}
                  {testimonial.authorName}
                </p>
              )}
              <div className="portfolio_category mt_40">
                <div className="p_category_item mb-30">
                  <h6 className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">{t('CLIENT')}: </h6>
                  <p className="f_size_15 f_400 mb-0">{item.client}</p>
                </div>
                {item.clientDescription && (
                  <div className="p_category_item mb-30">
                    <h6 className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">{t('CLIENT_DESC')}: </h6>
                    <p className="f_size_15 f_400 mb-0">{t(item.clientDescription)}</p>
                  </div>
                )}
                {item.date && (
                  <div className="p_category_item mb-30">
                    <h6 className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">{t('DATE')}: </h6>
                    <p className="f_size_15 f_400 mb-0">{item.date}</p>
                  </div>
                )}
                {item.website && (
                  <div className="p_category_item mb-30">
                    <h6 className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">{t('WEBSITE')}: </h6>
                    <a href={item.website} target="_blank" rel="noopener noreferrer" className="f_size_15 f_400 mb-0">
                      {item.website}
                    </a>
                  </div>
                )}
                <div className="p_category_item mb-30">
                  <h6 className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">{t('SERVICES')}: </h6>
                  <p className="f_size_15 f_400 mb-0">{item.services}</p>
                </div>
                {/* <div className="p_category_item">
                  <p className="f_400 f_size_15 mb-0">Share on</p>
                  <div className="social_icon">
                    <a href=".#">
                      <i className="ti-facebook"></i>
                    </a>
                    <a href=".#">
                      <i className="ti-twitter-alt"></i>
                    </a>
                    <a href=".#">
                      <i className="ti-google"></i>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="portfolio_details_gallery">
              {imagesFullUrls.map((img, index) => (
                <img key={img} onClick={() => openImageViewer(index)} className="img-fluid mb_20" src={img} alt="" />
              ))}
            </div>

            {isViewerOpen && (
              <ImageViewer
                src={imagesFullUrls}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                  backgroundColor: 'rgba(0,0,0,0.9)',
                  zIndex: 9999,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Portfoliosingle;
