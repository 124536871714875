export const jobPositionCategories = {
  frontend: 'frontend',
  backend: 'backend',
  fullstack: 'full-stack',
  embedded: 'embedded',
  mobile: 'mobile',
  ux: 'ux',
  unspecified: '',
};

export const jobPositionsData = [
  {
    title: 'React Developer',
    link: ['react-developer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.frontend,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'proficiency in modern React',
      'experience in any backend technology is a plus',
    ],
    technologies: ['React', 'JavaScript', 'TypeScript'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: 'Vue.js Developer',
    link: ['vue-developer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.frontend,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'proficiency in modern Vue.js',
      'experience in any backend technology is a plus',
    ],
    technologies: ['Vue.js', 'JavaScript', 'TypeScript'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: 'Java Developer',
    link: ['java-developer', 'senior-java-developer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.backend,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'expertise in Java from previous positions',
      'some experience in frontend-related work is a plus',
    ],
    technologies: ['Java', 'Spring Framework', 'Docker', 'Kubernetes', 'Microservices'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: '.NET Developer',
    link: ['dotnet-developer', 'senior-dotnet-developer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.backend,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'expertise in .NET from previous positions',
      'some experience in frontend-related work is a plus',
    ],
    technologies: ['.NET', 'C#', 'Docker', 'Kubernetes', 'Microservices'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: 'Machine Learning Engineer',
    link: ['machine-learning-engineer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.backend,
    salary: '15.000 - 23.000 + VAT (B2B)',
    expectations: [
      'good knowledge of Machine Learning concepts',
      'expertise in at least one popular ML framework / library',
    ],
    technologies: ['Python', 'R', 'C++', 'ETL', 'Big Data', 'Hadoop / Spark or similar (nice to have) ​'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: 'Mobile Developer',
    link: ['mobile-developer'],
    cities: ['Łódź', 'Remote'],
    published: false,
    category: jobPositionCategories.mobile,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'proficiency in React Native',
      'ability to write additional modules in native Android / iOS code',
      'experience in web development is a plus',
    ],
    technologies: ['React Native', 'Android', 'iOS'],
    allTechsRequired: false,
    englishRequired: true,
  },
  {
    title: 'UI / UX Designer',
    link: ['ui-ux-designer'],
    cities: ['Remote'],
    published: true,
    category: jobPositionCategories.ux,
    salary: null,
    expectations: [
      'a minimum of 1-2 years of professional experience',
      'proficiency in preparing responsive mobile apps wireframes / mockups',
    ],
    technologies: [],
    allTechsRequired: false,
    englishRequired: false,
  },
  {
    title: 'Software Developer',
    link: ['software-developer', 'senior-software-engineer'],
    cities: ['Łódź', 'Remote'],
    published: true,
    category: jobPositionCategories.unspecified,
    salary: '14.000 - 23.000 + VAT (B2B)',
    expectations: [
      'a minimum of 3-5 years of professional experience',
      'expertise in modern frontend and backend technologies from previous positions',
    ],
    technologies: ['React', 'Vue.js', 'Node.js', 'Java', 'Python', 'C#', 'Javascript', 'Typescript'],
    allTechsRequired: false,
    englishRequired: true,
  },
];
