import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import tagsData from '../../shared/data/tagsData';
import categoriesData from '../../shared/data/categoriesData';
import articlesData from '../../shared/data/articlesData';
import authorsData from '../../shared/data/authorsData';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';

const ArticlesSidebar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [searchPhrase, setSearchPhrase] = useState('');

  const categories = categoriesData[i18n.language] || [];
  const tags = tagsData[i18n.language] || [];
  const articles = articlesData().filter((article) => article.language === i18n.language);

  const categoriesCounter = {};
  for (const { categories } of articles) {
    for (const category of categories) {
      if (categoriesCounter[category] === undefined) {
        categoriesCounter[category] = 1;
      } else {
        categoriesCounter[category] += 1;
      }
    }
  }

  const recentArticles = articles.slice(0, 3);

  const searchArticles = (event) => {
    window.scrollTo(0, 0);
    history.push(searchPhrase ? `/articles?search=${searchPhrase}` : '/articles');
    event.preventDefault();
  };

  const searchPhraseChanged = (event) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <div className="blog-sidebar">
      <div className="widget sidebar_widget widget_search">
        <form onSubmit={searchArticles} className="search-form input-group">
          <input
            type="search"
            className="form-control widget_input"
            placeholder={t('SEARCH')}
            value={searchPhrase}
            onChange={searchPhraseChanged}
          />
          <button type="submit">
            <i className="ti-search"></i>
          </button>
        </form>
      </div>
      <div className="widget sidebar_widget widget_recent_post mt_60">
        <div className="widget_title">
          <h3 className="f_p f_size_20 t_color3">{t('RECENT_ARTICLES')}</h3>
          <div className="border_bottom"></div>
        </div>
        {recentArticles.map(({ id, title, image, authorId, link }) => {
          const { name: authorName } = authorsData.find(({ id }) => id === authorId);
          const articleLink = getLocalizedPath({ i18n, path: `/articles/${link}#back-to-articles` });
          return (
            <div className="media post_item" key={id}>
              <img src={require(`../../img/articles/${image}`)} alt="" />
              <div className="media-body">
                <Link to={articleLink}>
                  <h3 className="f_size_16 f_p f_400">{title}</h3>
                </Link>
                <div className="entry_post_info">
                  <Link to={articleLink}>{authorName}</Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="widget sidebar_widget widget_categorie mt_60">
        <div className="widget_title">
          <h3 className="f_p f_size_20 t_color3">{t('CATEGORIES')}</h3>
          <div className="border_bottom"></div>
        </div>
        <ul className="list-unstyled">
          {categories.map((category) => (
            <li key={category}>
              <Link to={getLocalizedPath({ i18n, path: `/articles?category=${category}#articles-list` })}>
                <span>{category}</span>
                <em>({categoriesCounter[category]})</em>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="widget sidebar_widget widget_tag_cloud mt_60">
        <div className="widget_title">
          <h3 className="f_p f_size_20 t_color3">{t('TAGS')}</h3>
          <div className="border_bottom"></div>
        </div>
        <div className="post-tags">
          {tags.map((tag) => (
            <Link key={tag} to={getLocalizedPath({ i18n, path: `/articles?tag=${tag}#articles-list` })}>
              {tag}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ArticlesSidebar;
