const testimonialsData = [
  {
    id: 12,
    image: 'testimonial-renta.png',
    authorName: 'Renta',
    authorPost: 'Joel Särkkä, CIO',
    description: 'TESTIMONIAL_RENTA',
  },
  {
    id: 1,
    image: 'testimonial-kiks.png',
    authorName: 'Kiks Technologies Oy',
    authorPost: 'Jukka Lepistö, Co-Founder',
    description: 'TESTIMONIAL_KIKS',
  },
  {
    id: 2,
    image: 'testimonial-speys.png',
    authorName: 'Speys Oy',
    authorPost: 'Juha Poutiainen, CTO',
    description: 'TESTIMONIAL_SPEYS',
  },
  {
    id: 3,
    image: 'testimonial-lvs-brokers.png',
    authorName: 'LVS Brokers Oy',
    authorPost: 'Software Development',
    description: 'TESTIMONIAL_LVS',
  },
  {
    id: 4,
    image: 'testimonial-robu.png',
    authorName: 'Robu Oy',
    authorPost: 'Software Development',
    description: 'TESTIMONIAL_ROBU',
  },
  {
    id: 5,
    image: 'testimonial-legal-jobs.png',
    authorName: 'Legal Jobs',
    authorPost: 'Software Development',
    description: 'TESTIMONIAL_LEGAL_JOBS',
  },
  {
    id: 6,
    image: 'testimonial-src-factory.png',
    authorName: 'SRC Factory Sp. z o.o.',
    authorPost: 'Software Development',
    description: 'TESTIMONIAL_SRC_FACTORY',
  },
  {
    id: 7,
    image: 'testimonial-puhujatori.png',
    authorName: 'Puhujatori Oy',
    authorPost: 'Mauri Ranta, CEO',
    description: 'TESTIMONIAL_PUHUJATORI',
  },
  {
    id: 8,
    image: 'testimonial-oulan.png',
    authorName: 'Oulan Group Oy',
    authorPost: 'Julius Manni, CEO',
    description: 'TESTIMONIAL_OULAN',
  },
  {
    id: 9,
    image: 'testimonial-efecte.png',
    authorName: 'Efecte',
    authorPost: 'Topias Marttila, CTO',
    description: 'TESTIMONIAL_EFECTE',
  },
  {
    id: 10,
    image: 'testimonial-medixine.png',
    authorName: 'Medixine',
    authorPost: 'Lasse Rousi, CEO',
    description: 'TESTIMONIAL_MEDIXINE',
  },
  {
    id: 11,
    image: 'testimonial-fleetguru.png',
    authorName: 'FleetGuru',
    authorPost: 'Indrek Siitan, Co-Founder & CTO',
    description: 'TESTIMONIAL_FLEETGURU',
  },
];

export default testimonialsData;
