const ServiceData = {
  STitle: "You SaasLand wherever your business agency",
  Service: [
    {
      id: 1,
      shape: "icon_shape1.png",
      icon: "ti-panel",
      text: "Export Presets",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 2,
      shape: "icon_shape2.png",
      icon: "ti-layout-grid2",
      text: "Grid and Guides",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 3,
      shape: "icon_shape3.png",
      icon: "ti-gallery",
      text: "Prototyping",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    },
    {
      id: 4,
      shape: "icon_shape4.png",
      icon: "ti-fullscreen",
      text: "Pixel Precision",
      description:
        "Why I say old chap that is spiffing bodge, blag pardon me buggered mufty Oxford butty bubble and squeak wind up, brown bread the full monty bloke ruddy.!",
      btn: "Read More",
      bicon: "ti-arrow-right"
    }
  ],
  agtext1: "",
  agtext2: "",
  agtext3: "",
  pServiceTitle: "Our Services",
  pServiceDetails:
    "Why I say old chap that is, spiffing jolly good a load of old tosh spend a penny tosser arse over.!",
  PaymentService: [
    {
      id: 1,
      image: "icon4.png",
      title: "Safer",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 2,
      image: "icon7.png",
      title: "Flexible",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 3,
      image: "icon5.png",
      title: "Convinient",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 4,
      image: "icon8.png",
      title: "Protected",
      description: "Well at public scho cheeky bugger grub burke."
    },
    {
      id: 5,
      image: "icon6.png",
      title: "World wide",
      description: "Well at public scho cheeky bugger grub burke."
    }
  ],
  HRService: [
    {
      id: "1",
      HRtitles: "HEALTHCARE_PACKAGE",
      HRdescription: "HEALTHCARE_PACKAGE_DESC",
      Hicon: "heart",
      rclass: "pr_70",
      iclass: "icon_one"
    },
    {
      id: "2",
      HRtitles: "SPORTS_CARD",
      HRdescription: "SPORTS_CARD_DESC",
      Hicon: "basketball",
      rclass: "pl_50 pr_20",
      iclass: "icon_two"
    },
    {
      id: "3",
      HRtitles: "LAPTOP_MONITOR",
      HRdescription: "LAPTOP_MONITOR_DESC",
      Hicon: "desktop",
      rclass: "pl_70",
      iclass: "icon_three"
    },
    {
      id: "4",
      HRtitles: "OFFICE_IN_CITY_CENTER",
      HRdescription: "OFFICE_IN_CITY_CENTER_DESC",
      Hicon: "home",
      rclass: "pr_70",
      iclass: "icon_four"
    },
    {
      id: "5",
      HRtitles: "PARKING_PLACE",
      HRdescription: "PARKING_PLACE_DESC",
      Hicon: "car",
      rclass: "pl_50 pr_20",
      iclass: "icon_five"
    },
    {
      id: "6",
      HRtitles: "FLEXIBLE_HOURS",
      HRdescription: "FLEXIBLE_HOURS_DESC",
      Hicon: "time",
      rclass: "pl_70",
      iclass: "icon_six"
    }
  ],
  rpost: [
    {
      id: "01",
      image: "blog-grid/post_1.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    },
    {
      id: "02",
      image: "blog-grid/post_2.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 10, 2019"
    },
    {
      id: "03",
      image: "blog-grid/post_3.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "jan 10, 2019"
    },
    {
      id: "04",
      image: "blog-grid/post_4.jpg",
      ptitle: "Proin gravi nibh velit auctor aliquet aenean.",
      admin: "Admin",
      date: "March 1, 2019"
    }
  ],
  work: [
    {
      id: "01",
      icon: "ti-settings one",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "02",
      icon: "ti-heart-broken two",
      title: "Premium plugins",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    },
    {
      id: "03",
      icon: "ti-target  three",
      title: "Live page builder",
      p:
        "Oxford jolly good cras bugger down the pub blow off well arse tinkety tonk old fruit William bite your arm off haggle, old it's all gone to pot daft no biggie bog.!"
    }
  ]
};
export default ServiceData;
