import React, { useState } from 'react';
import clsx from 'clsx';
import ArticlesSidebar from './ArticlesSidebar';
import ArticlesList from './ArticlesList';

const Articles = () => {
  const [pagination] = useState({ currentPage: 0, pagesCount: 1 });

  const { currentPage, pagesCount } = pagination;

  return (
    <section className="blog_area sec_pad" id="articles-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <ArticlesList />
            {pagesCount > 1 && (
              <ul className="list-unstyled page-numbers shop_page_number text-left mt_30">
                {[...Array(pagesCount + 1)].map((_e, i) => (
                  <li key={i}>
                    <span
                      className={clsx(
                        i === currentPage && 'page-numbers',
                        i === currentPage && 'current',
                        i === pagesCount && 'next'
                      )}
                    >
                      {i === currentPage ? i + 1 : null}
                      {i !== currentPage && i !== pagesCount ? (
                        <a className="page-numbers" href=".#">
                          {i + 1}
                        </a>
                      ) : null}
                      {i === pagesCount ? (
                        <a
                          className={clsx('next', 'page-numbers', currentPage === pagesCount - 1 && 'disabled')}
                          href=".#"
                        >
                          <i className="ti-arrow-right"></i>
                        </a>
                      ) : null}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="col-lg-4">
            <ArticlesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Articles;
