import React, { useState, useEffect } from 'react';
import parseISO from 'date-fns/parseISO';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import articlesData from '../../shared/data/articlesData';
import authorsData from '../../shared/data/authorsData';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';

const ArticlesList = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [articles, setArticles] = useState([]);

  const { tag, category, search } = qs.parse(location.search.slice(1));

  useEffect(() => {
    let articles = articlesData();

    if (search) {
      articles = articlesData().filter(
        ({ title, tags, categories }) => title.includes(search) || tags.includes(search) || categories.includes(search)
      );
    } else if (tag || category) {
      articles = articlesData().filter(({ tags, categories }) =>
        tag ? tags.includes(tag) : categories.includes(category)
      );
    }

    articles = articles.filter((article) =>
      Array.isArray(article.language) ? article.language.includes(i18n.language) : article.language === i18n.language
    );

    setArticles(articles);
  }, [i18n.language, tag, category, search]);

  return articles.length ? (
    articles.map(({ id, image, date, link, title, description, authorId }) => {
      const parsedDate = parseISO(date);
      const day = parsedDate.getDate();
      const month = parsedDate.toLocaleString(i18n.language, { month: 'short' });

      const { name: authorName } = authorsData.find(({ id }) => id === authorId);

      const articleLink = getLocalizedPath({ i18n, path: `/articles/${link}#back-to-articles` });
      return (
        <div className="blog_list_item mb_50" key={id}>
          <Link to={articleLink}>
            <img className="img-fluid cursor-pointer" src={require(`../../img/articles/${image}`)} alt="" />
          </Link>
          <div className="blog_content">
            <div className="post_date">
              <h2>
                {day} <span>{month}</span>
              </h2>
            </div>
            <div className="entry_post_info">
              <Link to={articleLink}>By: {authorName}</Link>
            </div>
            <Link to={articleLink}>
              <h5 className="f_p f_size_20 f_500 t_color mb_20">{title}</h5>
            </Link>
            <p className="f_400 mb_20">{description}</p>
            <Link to={articleLink} className="learn_btn_two">
              {t('READ_MORE')} <i className="ti-arrow-right"></i>
            </Link>
          </div>
        </div>
      );
    })
  ) : (
    <h3>No articles yet!</h3>
  );
};
export default ArticlesList;
