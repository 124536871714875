import { languages } from '../constants/languages';

const getLocalizedPath = ({ i18n, path }) => {
  const supportedLanguages = Object.values(languages);

  let languagePath = path;

  if (path === '/') {
    languagePath = languagePath.concat('', i18n.language);
    return languagePath;
  } else {
    for (const lang of supportedLanguages) {
      if (languagePath.startsWith(`/${lang}/`) || languagePath === `/${lang}`) {
        if (lang !== i18n.language) {
          languagePath = languagePath.replace(lang, i18n.language);
        }
        return languagePath;
      }
    }
    let languagePrefix = '/' + i18n.language;

    // ensure that path is prefixed with slash
    if (!languagePath.startsWith('/')) {
      languagePath = `/${languagePath}`;
    }

    return languagePrefix + languagePath;
  }
};

export default getLocalizedPath;
