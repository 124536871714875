import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { jobPositionsData } from './Careers/JobPositionsData';
import castArray from 'lodash/castArray';
import getLocalizedPath from '../shared/functions/getLocalizedPath';
import { HashLink as Link } from 'react-router-hash-link';

class CareersAction extends Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <section id="opportunities" className="action_area_three sec_pad">
        <div className="curved white"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="action_content text-center">
                <h2 className="f_600 f_size_30 l_height45 mb_40">{t('CAREERS_CALL_TO_ACTION')}</h2>
                {jobPositionsData.map((position, index) => (
                  <Link
                    key={index}
                    to={getLocalizedPath({ i18n, path: castArray(position.link)[0] })}
                    className="about_btn white_btn wow fadeInLeft"
                    data-wow-delay="0.3s"
                  >
                    {position.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(CareersAction);
