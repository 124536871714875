import React, { Component } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { languages } from './shared/constants/languages';

class ScrollToTopRoute extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, componentProps, render, ...rest } = this.props;

    const supportedLanguages = Object.values(languages);

    return (
      <Route
        {...rest}
        render={(props) => {
          return supportedLanguages.includes(props.match.params.locale) ? (
            <Component {...props} {...componentProps} />
          ) : (
            <Redirect to={`/${supportedLanguages[0]}/${props.match.params.locale}${props.location.search}`} />
          );
        }}
      />
    );
  }
}

export default withRouter(ScrollToTopRoute);
