import React from 'react';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <section id="software-development" className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require('../../img/illustrations/software-development.png')} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>{t('SOFTWARE_DEVELOPMENT')}</h2>
                  <p>{t('SOFTWARE_DEVELOPMENT_SHORT_DESC')}</p>
                  <div className="media seo_features_item">
                    <div className="icon one">
                      <i className="icon-user-follow"></i>
                    </div>
                    <div className="media-body">
                      <h3>{t('TEAM_EXTENSION')}</h3>
                      <p>{t('TEAM_EXTENSION_DESC')}</p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <i className="icon-settings"></i>
                    </div>
                    <div className="media-body">
                      <h3>{t('DIGITAL_PRODUCT_AND_SERVICE_DEV')}</h3>
                      <p>{t('DIGITAL_PRODUCT_AND_SERVICE_DEV_DESC')}</p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section id="software-maintenance" className="seo_features_one sec_pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="seo_features_img seo_features_img_two">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require('../../img/illustrations/software-maintenance.png')} alt="" />
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2 className="wow fadeInUp">{t('SOFTWARE_MAINTENANCE')}</h2>
                  <p className="wow fadeInUp">{t('SOFTWARE_MAINTENANCE_SHORT_DESC')}</p>
                  <p className="wow fadeInUp">{t('SOFTWARE_MAINTENANCE_DESC')}</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section id="nearshore-consulting" className="seo_features_one sec_pad">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="seo_features_img">
                <div className="round_circle"></div>
                <div className="round_circle two"></div>
                <img src={require('../../img/illustrations/nearshore-consulting.png')} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <Fade bottom cascade>
                <div className="seo_features_content">
                  <h2>{t('NEARSHORE_CONSULTING')}</h2>
                  <p>{t('NEARSHORE_CONSULTING_SHORT_DESC')}</p>
                  <div className="media seo_features_item">
                    <div className="icon one">
                      <i className="icon-briefcase"></i>
                    </div>
                    <div className="media-body">
                      <h3>{t('NEARSHORING_WORKSHOP')}</h3>
                      <p>{t('NEARSHORING_WORKSHOP_DESC')}</p>
                    </div>
                  </div>
                  <div className="media seo_features_item">
                    <div className="icon two">
                      <i className="icon-bubble"></i>
                    </div>
                    <div className="media-body">
                      <h3>{t('NEARSHORING_COACHING')}</h3>
                      <p>{t('NEARSHORING_COACHING_DESC')}</p>
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Services;
