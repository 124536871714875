import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from '../Team/Teamitem';
import { useTranslation } from 'react-i18next';
const Team = () => {
  const { t } = useTranslation();

  return (
    <section id="team" className="experts_team_area sec_pad">
      <div className="container">
        <Sectitle
          sClass="sec_title text-center mb_70"
          Title={t('POLAR_NIGHT_TEAM')}
          tClass="t_color3"
          TitleP={t('POLAR_NIGHT_TEAM_SHORT_DESC')}
        />
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="pawel-kruzynski.jpg"
              memberN="Paweł Krużyński"
              memberd="Python developer"
              memberBackground={t('PAWEL_KRUZYNSKI_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="klaudia-modrzejewska.jpg"
              memberN="Klaudia Modrzejewska"
              memberd="HR & Recruitment"
              memberBackground={t('KLAUDIA_MODRZEJEWSKA_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="aku-kajan.jpg"
              memberN="Aku Kajan"
              memberd="Commercial Director"
              memberBackground={t('AKU_KAJAN_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="maciej-polak.jpg"
              memberN="Maciej Polak"
              memberd=".NET developer"
              memberBackground={t('MACIEJ_POLAK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="henri-jaaskelainen.jpg"
              memberN="Henri Jääskeläinen"
              memberd="CEO"
              memberBackground={t('HENRI_JAASKELAINEN_SHORT_DESC')}
              phone="+48 731 407 479"
              phoneExtra="+358 40 595 7407"
              email="henri@polarnightsoftware.com"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="ilkka-suhonen.jpg"
              memberN="Ilkka Suhonen"
              memberd="Partner / Advisor"
              memberBackground={t('ILKKA_SUHONEN_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="marlena-kiryk.jpg"
              memberN="Marlena Kiryk"
              memberd="HR specialist"
              memberBackground={t('MARLENA_KIRYK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="rokko-karhuvaara.jpg"
              memberN="Rokko Karhuvaara"
              memberd="HR assistant"
              memberBackground={t('ROKKO_KARHUVAARA_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="mateusz-wozniak.jpg"
              memberN="Mateusz Woźniak"
              memberd="React / Node.js developer"
              memberBackground={t('MATEUSZ_WOZNIAK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="szczepan-gawlik.jpg"
              memberN="Szczepan Gawlik"
              memberd="Full-stack developer"
              memberBackground={t('SZCZEPAN_GAWLIK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="piotr-chojnacki.jpg"
              memberN="Piotr Chojnacki"
              memberd="CTO"
              memberBackground={t('PIOTR_CHOJNACKI_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="michal-piotrowski.jpg"
              memberN="Michał Piotrowski"
              memberd="Python developer"
              memberBackground={t('MICHAL_PIOTROWSKI_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="wojciech-brzozka.jpg"
              memberN="Wojciech Brzózka"
              memberd="Full-stack developer"
              memberBackground={t('WOJCIECH_BRZOZKA_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="szymon-grzelaczyk.jpg"
              memberN="Szymon Grzelaczyk"
              memberd="UI/UX designer"
              memberBackground={t('SZYMON_GRZELACZYK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="patryk-bartczyk.jpg"
              memberN="Patryk Bartczyk"
              memberd="Full-stack developer"
              memberBackground={t('PATRYK_BARTCZYK_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="margarita-nikolenko.jpg"
              memberN="Margarita Nikolenko"
              memberd="React developer"
              memberBackground={t('MARGARITA_NIKOLENKO_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="maciej-kowalski.jpg"
              memberN="Maciej Kowalski"
              memberd="Full-stack developer"
              memberBackground={t('MACIEJ_KOWALSKI_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="kamil-karpus.jpg"
              memberN="Kamil Karpus"
              memberd="Full-stack developer"
              memberBackground={t('KAMIL_KARPUS_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="pawel-zych.jpg"
              memberN="Paweł Zych"
              memberd="Full-stack developer"
              memberBackground={t('PAWEL_ZYCH_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="adrian-dworanowski.jpg"
              memberN="Adrian Dworanowski"
              memberd="Full-stack developer"
              memberBackground={t('ADRIAN_DWORANOWSKI_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="przemyslaw-szmigiel.jpg"
              memberN="Przemysław Szmigiel"
              memberd="Python developer"
              memberBackground={t('PRZEMYSLAW_SZMIGIEL_SHORT_DESC')}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Teamitem
              teamImage="zbigniew-sznajder.jpg"
              memberN="Zbigniew Sznajder"
              memberd="Full-stack developer"
              memberBackground={t('ZBIGNIEW_SZNAJDER_SHORT_DESC')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Team;
