import React from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';

const Service = () => {
  const { t, i18n } = useTranslation();
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad" id="services">
        <div className="container">
          <SeoTitle Title={t('OUR_SERVICES')} TitleP={t('OUR_SERVICES_DESCRIPTION')} />
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <Link to={getLocalizedPath({ i18n, path: '/services#software-development' })}>
                  <div className="seo_service_item">
                    <i className="app-icon ti-desktop one"></i>
                    <h4>{t('SOFTWARE_DEVELOPMENT')}</h4>
                    <p>{t('SOFTWARE_DEVELOPMENT_SHORT_DESC')}</p>
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    <img src={require('../../img/home4/process_4.png')} alt="" />
                  </div>
                </Link>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <Link to={getLocalizedPath({ i18n, path: '/services#software-maintenance' })}>
                  <div className="seo_service_item">
                    <i className="app-icon ti-settings two"></i>
                    <h4>{t('SOFTWARE_MAINTENANCE')}</h4>
                    <p>{t('SOFTWARE_MAINTENANCE_SHORT_DESC')}</p>
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    <img src={require('../../img/home4/process_1.png')} alt="" />
                  </div>
                </Link>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <Link to={getLocalizedPath({ i18n, path: '/services#nearshore-consulting' })}>
                  <div className="seo_service_item">
                    <i className="app-icon ti-comment three"></i>
                    <h4>{t('NEARSHORE_CONSULTING')}</h4>
                    <p>{t('NEARSHORE_CONSULTING_SHORT_DESC')}</p>
                    {/*<a href=".#"><i className="arrow_right"></i></a>*/}
                    <img src={require('../../img/home4/process_2.png')} alt="" />
                  </div>
                </Link>
              </div>
            </Fade>
            <div className="col-lg-12 text-center mt_40">
              <a href={getLocalizedPath({ i18n, path: '/services' })} className="seo_btn seo_btn_one btn_hover">
                {t('SEE_ALL_SERVICES')}
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
