import React, { Component } from 'react';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import testimonialsData from '../../shared/data/testimonialsData';

class MarketingTestimonial extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    const { t } = this.props;
    return (
      <section className="agency_testimonial_area bg_color sec_pad">
        <div className="container">
          <h2 className="f_size_30 f_600 t_color3 l_height40 text-center mb_60">{t('CUSTOMERS_ABOUT_US')}</h2>
          <div className="agency_testimonial_info">
            <Slider className="testimonial_slider" {...settings}>
              {testimonialsData.map((testimonial) => {
                return (
                  <div className="testimonial_item text-center left" key={testimonial.id}>
                    <div className="author_img">
                      <img src={require('../../img/testimonials/' + testimonial.image)} alt="" />
                    </div>
                    <div className="author_description">
                      <h4 className="f_500 t_color3 f_size_18">{testimonial.authorName}</h4>
                      <h6>{testimonial.authorPost}</h6>
                    </div>
                    <p>{t(testimonial.description)}</p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}
export default withTranslation()(MarketingTestimonial);
