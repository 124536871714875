import React from 'react';
import { languages } from '../constants/languages';
import { parseISO } from 'date-fns';

const articles = [
  {
    id: 1,
    language: languages.ENGLISH,
    image: 'lessons-from-a-fresh-entrepreneur.jpg',
    link: 'lessons-from-a-fresh-entrepreneur',
    title: 'Lessons from a fresh entrepreneur',
    description:
      'We have been building a new business in 2020 together with my partners and it’s a special year in so many ways already. In this article I list a few things that I have learned so far.',
    date: '2020-05-13T12:30:00',
    authorId: 1,
    tags: ['business', 'software development'],
    categories: ['business'],
    content: (
      <div>
        <p className="f_400 mb-30">
          We have been building a new business in 2020 together with my partners and it’s a special year in so many ways
          already. If I would have to list a few things that I have learned so far, I would say:
        </p>
        <p className="f_400 mb_40">
          1. Prepare to many different scenarios. In the end, none of those will probably turn into reality. Still, the
          planning will prepare you for the changes ahead. This tip was originally given to me by Jussi Sarkkinen, maybe
          not exactly in those words, but the message was the same.
        </p>
        <p className="f_400 mb-30">
          2. Know who you are and what you are doing. At the moment, we are contacting potential developer candidates
          and potential customers all the time. It’s important, that we don’t play a role but we try to be open about
          what we do and what we have to offer. I don’t want anyone to join this company with false expectations for
          example. That would not lead to a long cooperation. Same goes for potential customers. It’s best to have an
          open discussion about the customer's needs, ways of working and what we can offer. If it’s a match, great! If
          not, it’s better to find out early.
        </p>
        <p className="f_400 mb-30">
          3. It’s a marathon, not a sprint. Do what you need to do. Every day. Work hard if needed, but try to work
          smart, so that you will not work unnecessarily hard. It’s not easy, at least not for me always. Sometimes you
          notice you spent hours on some bureaucratic stuff, when you should be working on sales and recruitment. Then
          you stop, reflect, have a chat with your advisor, refocus and keep going.
        </p>
        <p className="f_400 mb-30">
          4. Trust your team. You have a team, let them do what they are best in. They do many things better than you
          do. Give up "command and control”. You will get better results than you expected.
        </p>
        <p className="f_400 mb-30">
          These lessons are not written from me to you. I’m not trying to tell I’m especially good with these. I wrote
          them more from me to me, myself and I. This is what I know makes my everyday work better. This is the path I
          try to follow.
        </p>
        <p className="f_400 mb-30">
          And hey, coming back to the point 3. and the sales part: If you need any help with your software development,
          feel free to contact <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a> 👍🏻
        </p>
      </div>
    ),
  },
  {
    id: 2,
    language: languages.FINNISH,
    image: 'lessons-from-a-fresh-entrepreneur.jpg',
    link: 'miksi-tekemisen-taso-on-korkealla-ohjelmistokehityksessa-puolassa',
    title: 'Miksi tekemisen taso on korkealla ohjelmistokehityksessä Puolassa?',
    description:
      'Olen asunut Puolassa nyt kaksi ja puoli vuotta ja tehnyt töitä Suomi-Puola -akselilla ohjelmistokehityksen parissa kuusi vuotta kuluneista viimeisestä kymmenestä vuodesta. Niille, jotka eivät Puolaa tunne, saattaa tulla yllätyksenä, miten kova tekemisen taso täällä on.',
    date: '2020-05-13T12:30:00',
    authorId: 1,
    tags: ['puola', 'ohjelmistokehitys', 'asenneratkaisee'],
    categories: ['ohjelmistokehitys'],
    content: (
      <div>
        <p className="f_400 mb_40">
          Olen asunut Puolassa nyt kaksi ja puoli vuotta ja tehnyt töitä Suomi-Puola -akselilla ohjelmistokehityksen
          parissa kuusi vuotta kuluneista viimeisestä kymmenestä vuodesta. Niille, jotka eivät Puolaa tunne, saattaa
          tulla yllätyksenä, miten kova tekemisen taso täällä on.
        </p>
        <p className="f_400 mb-30">Miksi tekemisen taso on Puolassa suhteellisen korkealla? Tässä omia havaintoja:</p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb-30">
              Markkina Puolassa on globaali ja tekijät pääsevät kiinni monipuolisempiin ja suurempiin projekteihin, kuin
              mitä monissa maissa on mahdollista.
            </li>
            <li className="mb-30">Puolassa on panostettu laadukkaaseen teknilliseen koulutukseen.</li>
            <li className="mb-30">
              Yleinen asenne on monella puolalaisella yhdistelmä kovaa työntekoa ja ripaus yrittäjämäistä ajattelua. Ei
              odoteta, että kukaan kattaa pöydän valmiiksi, vaan oman menestyksen eteen tehdään töitä.
            </li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Mielestäni kohta 3. on todella merkittävä. Asenne ratkaisee. Syitä siihen, miksi tuota asennetta löytyy täältä
          paljon, onkin sitten kaivettava pintaa syvemmältä...
        </p>
        <p className="f_400 mb-30">
          Jos kiinnostaa sparrata Puolan IT-osaajamarkkinasta syystä tai toisesta, kerron mielellään lisää omista
          kokemuksistani.
        </p>
        <p className="f_400 mb-30">
          Ole vapaasti yhteydessä: <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>
        </p>
      </div>
    ),
  },
  {
    id: 3,
    language: languages.FINNISH,
    image: 'mikko-kojon-product-director-sympa-oy-vinkit-hajautetun-ohjelmistokehitystiimin-kayttoon.jpg',
    link: 'mikko-kojon-product-director-sympa-oy-vinkit-hajautetun-ohjelmistokehitystiimin-kayttoon',
    title: 'Mikko Kojon (Product Director, Sympa Oy) vinkit hajautetun ohjelmistokehitystiimin käyttöön',
    description:
      'Meille on Polar Night Softwarella tärkeää ymmärtää millaisilla kokoonpanoilla ja miten erilaisissa yrityksissä kehitetään softaa. Erityisesti olemme kiinnostuneita siitä, miten ohjelmistokehitystyö on saatu sujumaan hajautetuissa tiimeissä.',
    date: '2020-05-14T10:30:00',
    authorId: 2,
    tags: ['puola', 'ohjelmistokehitys'],
    categories: ['ohjelmistokehitys'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Meille on Polar Night Softwarella tärkeää ymmärtää millaisilla kokoonpanoilla ja miten erilaisissa yrityksissä
          kehitetään softaa. Erityisesti olemme kiinnostuneita siitä, miten ohjelmistokehitystyö on saatu sujumaan
          hajautetuissa tiimeissä.
        </p>
        <p className="f_400 mb_40">
          Saimme vinkkejä nearshoringin hyödyntämiseen tuotekehityksessä Sympan tuotejohtajalta, Mikko Kojolta. Mikko
          johtaa yli 30 hengen tuotekehitysorganisaatiota Sympalla (<a href="https://www.sympa.com/">www.sympa.com</a>)
          ja he kehittävät Suomen tunnetuinta, globaalisti käytössä olevaa SaaS-pohjaista HR-järjestelmää yrityksille.
          Sympa on Pohjoismaiden nopeimmin kasvava HR-ratkaisujen tarjoaja. Sympa kehittää tuotettaan hajautetuissa
          ohjelmistokehitystiimeissä. Osa tiimistä työskentelee Suomessa ja osa Puolassa Gdanskissa. Mikko avasi meille
          ajatuksiaan hajautettujen tiimien johtamisesta, nearshoringista ja yleisesti konsulttien hyödyntämisestä
          tuotekehityksessä.
        </p>
        <p className="f_400 mb-30">
          Mitkä ovat nearshoringin keskeiset edut Sympan tuotekehitystä ajatellen? “Vaikka olemme HR-tech firma, ei
          rekrytointi ole meille ydinbusiness. Etenkin hyvien osaajien löytäminen on vaikeaa ja se vie aikaa “oikeilta
          töiltä”. Toisaalta kun ei hae halvinta tuntihintaa, saa tyypillisesti vastinetta rahoille. Nearshoringissa ei
          tarvitse välittää aikaeroista ja fyysinenkin tapaaminen on normaalioloissa helppo järjestää aina tarpeen
          mukaan. Ja koska me Sympalla käsittelemme henkilötietoja, on tärkeää, että kehittäjätkin tulevat EU:n sisältä.
          ”
        </p>
        <p className="f_400 mb-30">
          Miten tiimi Puolassa poikkeaa suomalaisista konsulteista? “Eipä se poikkea, jos englanti on muutenkin
          työkieli. Fyysinen tapaaminen on toki aina kivaa ja voi helpottaa workshoppaamista, mutta sekin saadaan oikein
          fasilitoiden toimimaan online. Tärkeitä kick-offeja ja vastaavia varten voi pieni tiimi tai tiimin edustaja
          hyvin lentää paikankin päälle.”
        </p>
        <p className="f_400 mb-30">
          Miksi juuri Puolasta löytyy vahvaa ohjelmistokehitysosaamista? “Sano sinä mitä siellä vesijohtoveteen
          laitetaan!”
        </p>
        <p className="f_400 mb-30">Mikon 5 vinkkiä konsulttien käytöstä osana yrityksen tuotekehitystä:</p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb_20">Tärkein: ajattele konsultteja omina työntekijöinä. Teillä on yhteinen päämäärä.</li>
            <li className="mb_20">Yhteistyö. Sekoita tiimejä. Ei mitään "meidän projekteja" ja "niiden projekteja"</li>
            <li className="mb_20">
              Tapaa pari kertaa fyysisestikin, kun taas mahdollista. Tuttujen kanssa on helppo työskennellä jatkossa.
            </li>
            <li className="mb_20">Kommunikoi avoimesti ja nosta mahdollisesti hiertävät asiat pöydälle. </li>
            <li className="mb_20">Ja vastavuoroisesti kuuntele.</li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Polar Night Software on kokeneiden IT-alan osaajien perustama yritys. Tavoitteena on tarjota asiakkaille
          tasokasta ohjelmistokehitystä parhaalla mahdollisella hinta-laatusuhteella. Jos haluat keskustella tarkemmin
          ohjelmistokehityspalveluista, ole yhteydessä Henri Jääskeläiseen,{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>.
        </p>
      </div>
    ),
  },
  {
    id: 4,
    language: languages.FINNISH,
    image: 'advisor-introduction-anssi-pakkanen.jpg',
    link: 'advisor-esittely-anssi-pakkanen',
    title: 'Advisor-esittely - Anssi Pakkanen',
    description:
      'Polar Night Softwaren vahvuus tulee ihmisistä. Tällä kertaa esittelyssä Anssi Pakkanen, partneri ja neuvonantaja. Anssi ei ole vain loistava myyntijohtaja vaikuttavalla ansioluettelolla, hän on myös mukava tyyppi! Annetaan Anssin puhua omasta puolestaan.',
    date: '2020-06-16T13:30:00',
    authorId: 3,
    tags: ['myynti', 'advisor', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Polar Night Softwaren vahvuus tulee ihmisistä. Tällä kertaa esittelyssä Anssi Pakkanen, partneri ja
          neuvonantaja. Anssi ei ole vain loistava myyntijohtaja vaikuttavalla ansioluettelolla, hän on myös mukava
          tyyppi! Annetaan Anssin puhua omasta puolestaan.
        </p>
        <p className="f_400 mb_40">
          <span className="f_600">
            1. Kerro tiiviisti omasta taustasta ja mitä haluat tuoda Polar Night Softwaren toimintaan?
          </span>
          <br />
          Olen monessa korporaatioliemessä keitetty ja sopivasti start-up -maailmassa ryvettynyt IT-myyntijohtaja, jolla
          on halua oppia uutta ja jakaa omia hyviä ja varsinkin niitä huonoja kokemuksia oman matkani varrelta.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">2. Mikä sai lähtemään mukaan taustavoimiin?</span>
          <br />
          Hyvä porukka, jolla on oikea asenne ja timanttinen kokemus. Henkan ja Ilkan ei tarvinnut kahta kertaa
          vihjaista mahdollisuudesta tulla mukaan Polar Night Softwaren taustavoimiin. Olin erittäin otettu tästä
          mahdollisuudesta ja valmis auttamaan.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">3. Miksi uskot Polar Night Softwaren potentiaaliin?</span>
          <br />
          Juuri tällä hetkellä tarvitaan markkinassa ketteryyttä, malttia ja nälkäistä asennetta. Kaikkea tätä tiedän
          Polar Night Softwaren ydinporukalla olevan yllin kyllin. Ajoitus markkinaan tulemiselle on mitä mainioin:
          tällä hetkellä jaetaan digitalisaation voittajatja häviäjät. Jos aiemmin organisaatio pystyi kehityshankkeilla
          pitämään yllä illuusiotaparemmasta huomisesta ilman strategista muutosta, nyt tilanne on täysin erilainen.
          Useimmissa organisaatioissa on kevään 2020 aikana laitettu kehityshankkeita jäihin.Tämä on merkki siitä, että
          kun kehitys yhdessä organisaatiossa pysähtyy, toiset menevät menojaan. Ikävä kyllä kehityksestä irtautuminen
          johtaa väistämättä tuhon tielle. Jos et ole valmis syömään, tulet itse syödyksi.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">4. Millaisena näet Polar Nightin tulevaisuuden/mahdollisuudet?</span>
          <br />
          Polar Night pystyy auttamaan asiakkaita, jotka todella haluavat selvitä voittajina digitalisaation
          armottomassa pudotuskilpailussa. Kustannusrakenne, tekemisen meininki ja kyky erottaa oikeat kehityshankkeet
          ovat tällä porukalla vertaansa vailla. Olen erittäin otettu mahdollisuudesta päästä mukaan tähän porukkaan ja
          odotan Polar Night Softwaren tulevaisuudelta todella paljon!
        </p>
      </div>
    ),
  },
  {
    id: 4,
    language: languages.ENGLISH,
    image: 'advisor-introduction-anssi-pakkanen.jpg',
    link: 'advisor-introduction-anssi-pakkanen',
    title: 'Advisor introduction - Anssi Pakkanen',
    description:
      'Our strength comes from the people in our team. This time we would like to introduce Anssi Pakkanen, partner and a member of our advisory team. Anssi is not just a great sales executive with a strong track record, he is also a nice person! But let’s let Anssi speak for himself.',
    date: '2020-06-16T13:30:00',
    authorId: 3,
    tags: ['sales', 'advisor', 'team'],
    categories: ['business'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Our strength comes from the people in our team. This time we would like to introduce Anssi Pakkanen, partner
          and a member of our advisory team. Anssi is not just a great sales executive with a strong track record, he is
          also a nice person! But let’s let Anssi speak for himself.
        </p>
        <p className="f_400 mb_40">
          <span className="f_600">
            1. Tell us briefly about your background and the things you wish to bring along to the Polar Night Software
            operations?
          </span>
          <br />I am an IT sales executive, seasoned through corporations and the start-up world, who wants to learn new
          things and share some good - and certainly the bad - experiences from my own journey.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">2. What inspired you to join the backup forces?</span>
          <br />A good team, the right attitude, and a first-rate experience. Henri and Ilkka didn’t need to tell me
          twice about a chance to join the Polar Night Software advisory team. I was extremely grateful for the
          opportunity and ready to help.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">3. What makes you believe in the potential of Polar Night Software?</span>
          <br />
          Right now, the market needs agility, patience, and a hungry attitude. I know that the Polar Night Software
          core team has all these qualities in abundance. The timing for entering the market couldn’t be better: this is
          the time when the winners and losers in the field of digitalisation are determined. Before, it was possible
          that an organisation could maintain an illusion of a better tomorrow without strategic change via development
          projects, but now the situation is completely different. Most organisations have put their development
          projects on hold during the spring of 2020.This is evident in how when development stops in one organisation,
          the others will keep moving forward. Unfortunately, detaching yourself from development will inevitably lead
          to ruin. If you are not ready to eat, you will get eaten yourself.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">4. How do you see Polar Night’s future/opportunities?</span>
          <br />
          Polar Night can help customers who truly want to come out as winners in the ruthless elimination challenge of
          digitalisation. Cost structure, the spirit of doing things together, and the ability to choose the right
          development projects are what this bunch is great at. I am really flattered to be offered the opportunity of
          joining this team and I am really looking forward to the future of Polar Night Software!
        </p>
      </div>
    ),
  },
  {
    id: 5,
    language: languages.ENGLISH,
    image: 'member-introduction-piotr-chojnacki.jpg',
    link: 'member-introduction-piotr-chojnacki',
    title: 'Member introduction - Piotr Chojnacki',
    description:
      'Let me give you a brief update on how I ended up in Polar Night Software and why do I think it has potential of becoming a company worth choosing as a long-term partner in scope of software and business development.',
    date: '2020-06-23T16:00:00',
    authorId: 4,
    tags: ['sales', 'team'],
    categories: ['business'],
    content: (
      <div>
        <p className="f_400 mb-30">
          The world around us is constantly changing. It is full of bright ideas from people who do their best to change
          it for the better and another tech unicorn may be hiding just around the corner. The list of ongoing projects
          all across the globe is stunning and it shows that people are indeed driven by a constant development.
        </p>
        <p className="f_400 mb-30">
          The pace at which the world moves forward, though, is not our full potential yet and from my own experience I
          can tell that it's not lack of financial resources that is holding many of these projects back. Instead, in
          most cases the issue is lack of experienced people that could be able to cope with running the most demanding
          projects, but also lack of trust for the companies that are.
        </p>
        <p className="f_400 mb-30">
          Let me give you a brief update on how I ended up in Polar Night Software and why do I think it has potential
          of becoming a company worth choosing as a long-term partner in scope of software and business development.
        </p>
        <p className="f_400 mb-30">
          Since the beginning of my professional career, I've been involved in various technical fields. I have worked
          with mobile telecommunications radio systems (GSM / CDMA), had a chance to work on Android system core. For
          many years I was involved in projects for the key players from automotive industry which were focused on
          building cutting edge software for embedded devices. I was responsible for building custom web browsers based
          on WebKit and Blink engines, where I could contribute to the open source community. I had a pleasure to build
          a software for vehicles identification using latest computer vision techniques and I have also worked on web
          development which is now one of the most dynamically evolving field of software engineering.
        </p>
        <p className="f_400 mb-30">
          All these opportunities helped me to understand the key factors of building highest quality solutions, but it
          also taught me about business-related problems that are the direct or indirect causes of failures in many IT
          projects. Here is a list of most crucial - in my opinion - factors which may lead your newly emerging startup
          or long existing company to the successful future:
        </p>
        <p className="f_400 mb_40">
          1. Trust - without trust there's no foundation for building a solid product. It relates both to your own
          employees, but also to the partner company that is responsible for your software services. Without it, you
          constantly pay attention to every tiniest detail of your partner's work, which costs you a valuable time that
          could be better used for other business activities.
        </p>
        <p className="f_400 mb-30">
          2. Experience - By taking your time for selecting the most experienced engineers and partners based on your
          business needs, you naturally find yourself on the right path to building a trust with them.
        </p>
        <p className="f_400 mb-30">
          3. Communication - A lack of proper communication is usually an insidious cause of failures in meeting the
          deadlines and reaching the expected quality of the product. You may feel like extensive communication is a
          contradiction to the trust about which I mentioned previously, but in fact it's not. As an addition to trust
          in your partner's experience, it is crucial to understand that we are all just humans and whenever there's
          some kind of misunderstanding between the people involved, a confusion in requirements or actually any other
          problem worth discussing, there should be always a way to be in touch with your partner on a regular basis in
          order to be able to work as efficiently, as possible.
        </p>
        <p className="f_400 mb-30">
          At some point in my life I understood that I have enough qualifications and knowledge to help others to deal
          with these problems, hence I decided to join forces with other Polar Night Software members to build the
          leading software solutions for our clients. We all share the same vision and we understand that only with hard
          work and constant learning we are able to earn trust of our customers.
        </p>
        <p className="f_400 mb-30">
          Should you find yourself interested in hearing more about the way we work and the technologies we can support
          you with, do not hesitate to get in touch with our CEO, Henri Jääskeläinen:{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>
        </p>
      </div>
    ),
  },
  {
    id: 6,
    language: languages.FINNISH,
    image: 'member-introduction-piotr-chojnacki.jpg',
    link: 'member-introduction-piotr-chojnacki',
    title: 'Member introduction - Piotr Chojnacki',
    description:
      'Let me give you a brief update on how I ended up in Polar Night Software and why do I think it has potential of becoming a company worth choosing as a long-term partner in scope of software and business development.',
    date: '2020-06-23T16:00:00',
    authorId: 4,
    tags: ['myynti', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          The world around us is constantly changing. It is full of bright ideas from people who do their best to change
          it for the better and another tech unicorn may be hiding just around the corner. The list of ongoing projects
          all across the globe is stunning and it shows that people are indeed driven by a constant development.
        </p>
        <p className="f_400 mb-30">
          The pace at which the world moves forward, though, is not our full potential yet and from my own experience I
          can tell that it's not lack of financial resources that is holding many of these projects back. Instead, in
          most cases the issue is lack of experienced people that could be able to cope with running the most demanding
          projects, but also lack of trust for the companies that are.
        </p>
        <p className="f_400 mb-30">
          Let me give you a brief update on how I ended up in Polar Night Software and why do I think it has potential
          of becoming a company worth choosing as a long-term partner in scope of software and business development.
        </p>
        <p className="f_400 mb-30">
          Since the beginning of my professional career, I've been involved in various technical fields. I have worked
          with mobile telecommunications radio systems (GSM / CDMA), had a chance to work on Android system core. For
          many years I was involved in projects for the key players from automotive industry which were focused on
          building cutting edge software for embedded devices. I was responsible for building custom web browsers based
          on WebKit and Blink engines, where I could contribute to the open source community. I had a pleasure to build
          a software for vehicles identification using latest computer vision techniques and I have also worked on web
          development which is now one of the most dynamically evolving field of software engineering.
        </p>
        <p className="f_400 mb-30">
          All these opportunities helped me to understand the key factors of building highest quality solutions, but it
          also taught me about business-related problems that are the direct or indirect causes of failures in many IT
          projects. Here is a list of most crucial - in my opinion - factors which may lead your newly emerging startup
          or long existing company to the successful future:
        </p>
        <p className="f_400 mb_40">
          1. Trust - without trust there's no foundation for building a solid product. It relates both to your own
          employees, but also to the partner company that is responsible for your software services. Without it, you
          constantly pay attention to every tiniest detail of your partner's work, which costs you a valuable time that
          could be better used for other business activities.
        </p>
        <p className="f_400 mb-30">
          2. Experience - By taking your time for selecting the most experienced engineers and partners based on your
          business needs, you naturally find yourself on the right path to building a trust with them.
        </p>
        <p className="f_400 mb-30">
          3. Communication - A lack of proper communication is usually an insidious cause of failures in meeting the
          deadlines and reaching the expected quality of the product. You may feel like extensive communication is a
          contradiction to the trust about which I mentioned previously, but in fact it's not. As an addition to trust
          in your partner's experience, it is crucial to understand that we are all just humans and whenever there's
          some kind of misunderstanding between the people involved, a confusion in requirements or actually any other
          problem worth discussing, there should be always a way to be in touch with your partner on a regular basis in
          order to be able to work as efficiently, as possible.
        </p>
        <p className="f_400 mb-30">
          At some point in my life I understood that I have enough qualifications and knowledge to help others to deal
          with these problems, hence I decided to join forces with other Polar Night Software members to build the
          leading software solutions for our clients. We all share the same vision and we understand that only with hard
          work and constant learning we are able to earn trust of our customers.
        </p>
        <p className="f_400 mb-30">
          Should you find yourself interested in hearing more about the way we work and the technologies we can support
          you with, do not hesitate to get in touch with our CEO, Henri Jääskeläinen:{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>
        </p>
      </div>
    ),
  },
  {
    id: 7,
    language: languages.ENGLISH,
    image: 'member-introduction-mateusz-wojciechowski.jpg',
    link: 'member-introduction-mateusz-wojciechowski',
    title: 'Member introduction - Mateusz Wojciechowski',
    description:
      'The golden era of science is now. Yet, there are lots of questions when we will reach the level of development when "impossible is nothing" will be truth. Will it be a revolution? Or maybe continuous progress built on slow steps that sum into big change?',
    date: '2020-07-01T10:00:00',
    authorId: 5,
    tags: ['sales', 'team'],
    categories: ['business'],
    content: (
      <div>
        <p className="f_400 mb-30">
          The golden era of science is now. Yet, there are lots of questions when we will reach the level of development
          when "impossible is nothing" will be truth. Will it be a revolution? Or maybe continuous progress built on
          slow steps that sum into big change?
        </p>
        <p className="f_400 mb-30">
          From 4004 microprocessor released by Intel in 70s till now it was 50 years. Half of century seems to be lots
          of time especially when you notice that they just aimed for a simple calculator. Nowadays, much more powerful
          devices we can find on our wrists used as smartwatches. The computing power reaches level when things that
          were considered as science fiction twenty years ago now are standard, emotionless routines.
        </p>
        <p className="f_400 mb-30">
          I am a software engineer with 10 years of experience including 7 years of commercial work and I have seen a
          slice of history during this time. In the beginning of my career I was tightly connected with the development
          of embedded solutions for control units used in automation projects. My interests were connected with DSP
          technologies, bioinformatics and electrical drives control systems. I have enjoyed designing systems that were
          providing output that could be converted into real world physical feature like engine torque, heat, cold,
          magnetic force, light or sound. In short words, creating highly efficient systems that could acquire data,
          process it and use to change environment was my main concern. I have dreamed of developing systems that would
          behave as real lifeforms. This is also my private definition of robotics.
        </p>
        <p className="f_400 mb-30">
          During my commercial career I have faced many problems that are natural species of IT world. Firstly I was
          afraid, as I knew that some of them are predators. After some time I understood that I need - similarly to a
          good biologist - to observe, analyse and understand them to survive in modern developers environment.
        </p>
        <p className="f_400 mb-30">
          In last few years I met lots of interesting, professional people. They were coming from different parts of the
          world. Each of them gave me a new lesson. A tool that can be used in self-improvement process. I have learned
          how to listen, how to discuss, where to look for information and how to be kind even when I am tired, hungry
          and angry. To be honest, people are the best part of IT, not the technology. There is simply no way to create
          a great technology without great people.
        </p>
        <p className="f_400 mb_40">
          As the most popular law stated by Sir Arthur Charles Clarke says:{' '}
          <i>" Any sufficiently advanced technology is indistinguishable from magic."</i>, we can consider an engineer
          as a magician. If you have some ideas, even the ones that might sound ridiculously, and want to pour a bit of
          magic into them, do not hesitate and contact our CEO, Henri Jääskeläinen:{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>
        </p>
      </div>
    ),
  },
  {
    id: 8,
    language: languages.FINNISH,
    image: 'member-introduction-mateusz-wojciechowski.jpg',
    link: 'member-introduction-mateusz-wojciechowski',
    title: 'Member introduction - Mateusz Wojciechowski',
    description:
      'The golden era of science is now. Yet, there are lots of questions when we will reach the level of development when "impossible is nothing" will be truth. Will it be a revolution? Or maybe continuous progress built on slow steps that sum into big change?',
    date: '2020-07-01T10:00:00',
    authorId: 5,
    tags: ['myynti', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          The golden era of science is now. Yet, there are lots of questions when we will reach the level of development
          when "impossible is nothing" will be truth. Will it be a revolution? Or maybe continuous progress built on
          slow steps that sum into big change?
        </p>
        <p className="f_400 mb-30">
          From 4004 microprocessor released by Intel in 70s till now it was 50 years. Half of century seems to be lots
          of time especially when you notice that they just aimed for a simple calculator. Nowadays, much more powerful
          devices we can find on our wrists used as smartwatches. The computing power reaches level when things that
          were considered as science fiction twenty years ago now are standard, emotionless routines.
        </p>
        <p className="f_400 mb-30">
          I am a software engineer with 10 years of experience including 7 years of commercial work and I have seen a
          slice of history during this time. In the beginning of my career I was tightly connected with the development
          of embedded solutions for control units used in automation projects. My interests were connected with DSP
          technologies, bioinformatics and electrical drives control systems. I have enjoyed designing systems that were
          providing output that could be converted into real world physical feature like engine torque, heat, cold,
          magnetic force, light or sound. In short words, creating highly efficient systems that could acquire data,
          process it and use to change environment was my main concern. I have dreamed of developing systems that would
          behave as real lifeforms. This is also my private definition of robotics.
        </p>
        <p className="f_400 mb-30">
          During my commercial career I have faced many problems that are natural species of IT world. Firstly I was
          afraid, as I knew that some of them are predators. After some time I understood that I need - similarly to a
          good biologist - to observe, analyse and understand them to survive in modern developers environment.
        </p>
        <p className="f_400 mb-30">
          In last few years I met lots of interesting, professional people. They were coming from different parts of the
          world. Each of them gave me a new lesson. A tool that can be used in self-improvement process. I have learned
          how to listen, how to discuss, where to look for information and how to be kind even when I am tired, hungry
          and angry. To be honest, people are the best part of IT, not the technology. There is simply no way to create
          a great technology without great people.
        </p>
        <p className="f_400 mb_40">
          As the most popular law stated by Sir Arthur Charles Clarke says:{' '}
          <i>" Any sufficiently advanced technology is indistinguishable from magic."</i>, we can consider an engineer
          as a magician. If you have some ideas, even the ones that might sound ridiculously, and want to pour a bit of
          magic into them, do not hesitate and contact our CEO, Henri Jääskeläinen:{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a>,{' '}
          <a href="tel:358405957407">(+358) 40 595 7407</a>
        </p>
      </div>
    ),
  },
  {
    id: 9,
    language: languages.ENGLISH,
    image: 'a-teams-self-determination-and-a-decentralised-team.jpg',
    link: 'a-teams-self-determination-and-a-decentralised-team',
    title: 'A team’s self-determination and a decentralised team',
    description:
      'Self-determination has been a trendy word in software development for a long while now, and it is used to describe the practice of decreasing hierarchy and transferring the decision-making power to people working in the customer interface and/or close to the product or service, i.e. close to the ‘ground’.',
    date: '2020-09-28T10:00:00',
    authorId: 1,
    tags: ['software development', 'team'],
    categories: ['software development'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Self-determination has been a trendy word in software development for a long while now, and it is used to
          describe the practice of decreasing hierarchy and transferring the decision-making power to people working in
          the customer interface and/or close to the product or service, i.e. close to the ‘ground’.
        </p>
        <p className="f_400 mb-30">
          In decentralised teams, the teams’ need for self-determination, or self-governance, becomes strongly
          highlighted. When software developers, designers and end customers can all be in different locations,
          manager-led management style simply does not work. A manager can no longer hold the team members’ hands and be
          constantly present, as physical distance makes this more difficult. In the name of efficiency, the team
          members must be given more power to make decisions about their own work methods, and all communication must
          take place directly between the people involved in the case. The number of middlemen in all communication must
          be minimised, regardless of what role they happen to play. The decision-making power concerning the ways of
          working, the tools, and the work’s architecture must be handed over to the team members. All unnecessary
          permit applications from someone higher up in the company hierarchy slows down the work itself and, at worst,
          can lead to wrong choices if the expert’s opinion is ignored. If this sounds frightening, here are a few words
          of comfort: a mature team of experts will not suddenly decide to change architectures just for the fun of it,
          as they are also able to consider the end customer’s business perspective.
        </p>
        <p className="f_400 mb-30">
          But what is the correct amount of self-determination in a team? No one can answer this question on your
          behalf; in the end, it is all about the boundaries within which the team have the freedom to decide on
          matters. If your organisation is just starting its journey towards self-determination, you should proceed one
          step at a time. In an organisation with previous strong manager leadership, individual software developers or
          even whole teams can feel like they have been abandoned if they suddenly cannot get the answers and guidance
          they are used to. However, the limits to this freedom should be set far enough that they create a large enough
          ‘box’ where intrinsic motivation can be born and grow. When a person realises that their opinions, expertise
          and decisions truly have a meaning, their intrinsic motivation starts to grow, resulting in both a happier
          employee and a more satisfied end customer.
        </p>
        <p className="f_400 mb-30">
          And finally, the self-determination of teams is not in any way linked to only decentralised teams. In the same
          way, your own team in the corner of the office also work better when power and responsibility meet, and
          intrinsic motivation helps carry the operations to a whole new level.
        </p>
        <p className="f_400 mb-30">
          We encourage the self-determination of our developers. If you need any help with your software development,
          feel free to contact <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a> 👍🏻
        </p>
      </div>
    ),
  },
  {
    id: 10,
    language: languages.FINNISH,
    image: 'a-teams-self-determination-and-a-decentralised-team.jpg',
    link: 'tiimin-itseohjautuvuus-ja-hajautettu-tiimi',
    title: 'Tiimin itseohjautuvuus ja hajautettu tiimi',
    description:
      'Ohjelmistokehityksessä itseohjautuvuus on ollut jo pitkään trendisana, jolla halutaan kuvata hierarkian vähentämistä ja päätöksenteon siirtämistä niille henkilöille, jotka ovat asiakasrajapinnassa ja/tai lähellä tuotetta tai palvelua “kädet savessa”.',
    date: '2020-09-28T10:00:00',
    authorId: 1,
    tags: ['ohjelmistokehitys', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Ohjelmistokehityksessä itseohjautuvuus on ollut jo pitkään trendisana, jolla halutaan kuvata hierarkian
          vähentämistä ja päätöksenteon siirtämistä niille henkilöille, jotka ovat asiakasrajapinnassa ja/tai lähellä
          tuotetta tai palvelua “kädet savessa”.
        </p>
        <p className="f_400 mb-30">
          Hajautetuissa tiimeissä tiimin itseohjautuvuuden tarve (englanniksi useita termejä käytössä, hiukan eri
          painotuksilla mm. self-governance) nousee vahvasti esille. Kun ohjelmistokehittäjät, designerit ja
          loppuasiakkaat ovat mahdollisesti kaikki eri lokaatioissa, ei managerivetoinen johtamistyyli yksinkertaisesti
          toimi. Manageri ei voi enää pitää kädestä ja olla jatkuvasti fyysisesti läsnä, koska fyysinen etäisyys tekee
          siitä vaikeampaa. Tehokkuuden nimissä on pakko antaa tiimille enemmän valtaa päättää työskentelytavoistaan ja
          kaiken kommunikaation tulee tapahtua suoraan niiden henkilöiden välillä, jotka asiaan liittyvät. Viestinnässä
          välikädet tulee minimoida riippumatta siitä, mikä heidän roolinsa nimi sattuu olemaan. Päätöksenteossa valta
          tehdä päätöksiä työnteon tavoista, työkaluista ja arkkitehtuurista tulee viedä tiimin jäsenille. Jokainen
          ylimääräinen luvan anominen hierarkiassa ylempänä olevalta taholta hidastaa itse toteutusta ja pahimmillaan
          johtaa vääriin valintoihin, jos asiantuntijamielipide ohitetaan. Jos tämä pelottaa, niin lohdutuksen sana: ei
          kypsä asiantuntijatiimi yhtäkkiä päätä vaihtaa arkkitehtuuria toiseen huvin vuoksi, vaan osaa ottaa huomioon
          myös loppuasiakkaan liiketoimintanäkökulman.
        </p>
        <p className="f_400 mb-30">
          Mikä on sitten oikea määrä itseohjautuvuutta tiimissä? Siihen kysymykseen ei kukaan voi antaa puolestasi
          vastausta, mutta viime kädessä kyse on siitä, minkälaisten rajojen sisällä tiimillä on vapaus päättää
          asioista. Jos organisaatiosi on tiimien itseohjautuvuuden suhteen alkutaipaleella, kannattaa edetä askel
          kerrallaan. Aiemmin vahvasti manageriohjautuvassa organisaatiossa yksittäinen ohjelmistokehittäjä tai
          kokonainen tiimikin voi tuntea jääneensä heitteille, jos yhtäkkiä vastauksia ja ohjausta ei saakaan kuten
          ennen. Ota vapauden rajojen tavoitteeksi kuitenkin niin iso “laatikko”, että sen sisällä on sisäisen
          motivaation on mahdollista syntyä ja kukoistaa. Kun ihminen huomaa, että hänen mielipiteillään,
          asiantuntijuudellaan ja päätöksillään on merkitystä, alkaa sisäinen motivaatio kasvaa ja lopputuloksena on
          tyytyväisempi asiantuntija sekä loppuasiakas.
        </p>
        <p className="f_400 mb-30">
          Ja lopuksi: ei tiimien itseohjautuvuus ole mitenkään sidottu hajautettuihin tiimeihin. Se oma tiimi siellä
          toimiston nurkassa toimii aivan samalla tavalla paremmin, kun valta ja vastuu kohtaavat ja sisäinen motivaatio
          vie tekemistä uudelle tasolle. Kannustamme ohjelmistokehittäjiämme itseohjautuvuuteen. Jos etsit vahvistusta
          ohjelmistokehitykseesi, ota yhteyttä:{' '}
          <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a> 👍🏻
        </p>
      </div>
    ),
  },
  {
    id: 11,
    language: languages.ENGLISH,
    image: 'an-individuals-self-determination-and-a-decentralised-team.jpg',
    link: 'an-individuals-self-determination-and-a-decentralised-team',
    title: 'An individual’s self-determination and a decentralised team',
    description:
      'In a decentralised team, also the need for individuals’ self-determination becomes highlighted. Self-management is a crucial skill that all team members should have, especially when each team member works at a different location. The ability to ask other team members for help when necessary is also high up on the list of desirable personal traits. When you are physically in the same space with others, it is often more natural and automatic to ask a colleague for advice, but in decentralised teams this action must take a more concrete form so that the message will get through via an instant messaging service or video call.',
    date: '2020-10-08T11:00:00',
    authorId: 1,
    tags: ['software development', 'team'],
    categories: ['software development'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Previously, we talked about the self-determination of a team (
          <a href="https://polarnightsoftware.com/en/articles/a-teams-self-determination-and-a-decentralised-team">
            here
          </a>
          ). But if we want teams to have more self-determination, what does this mean to individuals?
        </p>
        <p className="f_400 mb-30">
          In a decentralised team, also the need for individuals’ self-determination becomes highlighted.
          Self-management is a crucial skill that all team members should have, especially when each team member works
          at a different location. The ability to ask other team members for help when necessary is also high up on the
          list of desirable personal traits. When you are physically in the same space with others, it is often more
          natural and automatic to ask a colleague for advice, but in decentralised teams this action must take a more
          concrete form so that the message will get through via an instant messaging service or video call.
        </p>
        <p className="f_400 mb-30">
          Based on the self-determination theory (
          <a href="https://en.wikipedia.org/wiki/Self-determination_theory">
            https://en.wikipedia.org/wiki/Self-determination_theory
          </a>
          ), a person has three psychological basic needs that have to be met to facilitate their development and
          well-being:
        </p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb_20">Autonomy</li>
            <li className="mb_20">Competence</li>
            <li className="mb_20">Relatedness</li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Based on these basic needs, it is easy to come up with a few tips on how to support the self-determination of
          an individual person, or, in this case, an expert:
        </p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb_20">
              Allow the expert the freedom to make the decisions of their own working methods and the details related to
              the project to be completed, while still remaining a member of their team.
            </li>
            <li className="mb_20">
              Ensure that each expert has the competence needed in their work and, thereby, the chance to achieve
              results.
            </li>
            <li className="mb_20">
              Support the team spirit and attempt to promote communality. In decentralised teams, this can be supported
              through occasional physical meetings and shared unofficial instant messaging channels unrelated to work.
            </li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Even if working with software may seem technical in nature, it is very much still work that takes place with
          and between people. Decentralised teams are not an exception to this, but instead require even slightly more
          attention regarding these softer perspectives than the teams that are physically in the same location.
        </p>
        <p className="f_400 mb-30">
          We encourage the self-determination of our developers. If you need any help with your software development,
          feel free to contact <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a> 👍🏻
        </p>
      </div>
    ),
  },
  {
    id: 12,
    language: languages.FINNISH,
    image: 'an-individuals-self-determination-and-a-decentralised-team.jpg',
    link: 'yksilon-itseohjautuvuus-ja-hajautettu-tiimi',
    title: 'Yksilön itseohjautuvuus ja hajautettu tiimi',
    description:
      'Hajautetussa tiimissä myös yksilön itseohjautuvuuden tarve korostuu. Erityisesti, jos jokainen tiimin jäsen toimii eri lokaatiossa, on itsensä johtaminen tärkein taito, joka tiimin jäsenellä tulee olla. Kyky pyytää apua muilta tiimin jäseniltä silloin kun sitä tarvitaan on myös korkealla toivottujen henkilökohtaisten ominaisuuksien listalla. Kun ollaan fyysisesti samassa tilassa on usein luontevampaa kysyä vinkkiä kollegalta ikään kuin huomaamatta, mutta hajautetussa tiimissä se on tehtävä konkreettisemmin, jotta viesti varmasti välittyy pikaviestimen tai videopuhelun välityksellä.',
    date: '2020-10-08T11:00:00',
    authorId: 1,
    tags: ['ohjelmistokehitys', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Aiemmin käsittelimme tiimin itseohjautuvuutta (
          <a href="https://polarnightsoftware.com/fi/articles/tiimin-itseohjautuvuus-ja-hajautettu-tiimi">täällä</a>
          ). Mutta jos tiimeistä halutaan itseohjautuvampia, niin mitä se tarkoittaa yksilön kannalta?
        </p>
        <p className="f_400 mb-30">
          Hajautetussa tiimissä myös yksilön itseohjautuvuuden tarve korostuu. Erityisesti, jos jokainen tiimin jäsen
          toimii eri lokaatiossa, on itsensä johtaminen tärkein taito, joka tiimin jäsenellä tulee olla. Kyky pyytää
          apua muilta tiimin jäseniltä silloin kun sitä tarvitaan on myös korkealla toivottujen henkilökohtaisten
          ominaisuuksien listalla. Kun ollaan fyysisesti samassa tilassa on usein luontevampaa kysyä vinkkiä kollegalta
          ikään kuin huomaamatta, mutta hajautetussa tiimissä se on tehtävä konkreettisemmin, jotta viesti varmasti
          välittyy pikaviestimen tai videopuhelun välityksellä.
        </p>
        <p className="f_400 mb-30">
          Itseohjautuvuusteorian (
          <a href="https://en.wikipedia.org/wiki/Self-determination_theory">
            https://en.wikipedia.org/wiki/Self-determination_theory
          </a>
          ), mukaan ihmisellä on kolme psykologista perustarvetta, joiden pitää toteutua, jotta kehitys ja hyvinvointi
          on mahdollista:
        </p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb_20">Omaehtoisuus</li>
            <li className="mb_20">Kyvykkyys</li>
            <li className="mb_20">Yhteisöllisyys</li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Näiden tarpeiden perusteella on helppo luoda muutama ohje yksilön, tässä tapauksessa asiantuntijan,
          itseohjautuvuuden tukemiseksi:
        </p>
        <p className="f_400 mb-30">
          <ol>
            <li className="mb_20">
              Anna asiantuntijalle vapautta päättää, vaikkakin tiiminsä jäsenenä, työskentelytavoistaan ja
              toteutettavaan kokonaisuuteen liittyvistä yksityiskohdista.
            </li>
            <li className="mb_20">
              Pidä huolta, että jokaisella asiantuntijalla on työhönsä tarvittava osaaminen ja tätä kautta mahdollisuus
              saada asioita aikaan.
            </li>
            <li className="mb_20">
              Tue tiimiytymistä ja pyri luomaan yhteisöllisyyttä. Hajautetuissa tiimeissä esimerkiksi tapaaminen silloin
              tällöin fyysisesti ja pikaviestinten epäviralliset, työn ulkopuolisia aiheita käsittelevät kanavat
              palvelevat tätä tarkoitusta.
            </li>
          </ol>
        </p>
        <p className="f_400 mb-30">
          Vaikka ohjelmistotyö voi tuntua tekniseltä, se on mitä suurimmassa määrin työtä ihmisten kanssa ja kesken.
          Hajautetut tiimit eivät ole mikään poikkeus tässä suhteessa, vaan vaativat jopa hieman fyysisesti samassa
          lokaatiossa olevaa tiimiä enemmän huomiota näille pehmeille näkökohdille.
        </p>
        <p className="f_400 mb-30">
          Kannustamme ohjelmistokehittäjiämme itseohjautuvuuteen. Jos etsit vahvistusta ohjelmistokehitykseesi, ota
          yhteyttä: <a href="mailto:henri@polarnightsoftware.com">henri@polarnightsoftware.com</a> 👍🏻
        </p>
      </div>
    ),
  },
  {
    id: 13,
    language: languages.ENGLISH,
    image: 'advisor-introduction-ilkka-suhonen.jpg',
    link: 'advisor-introduction-ilkka-suhonen',
    title: 'Advisor introduction - Ilkka Suhonen',
    description:
      'Finally it’s the time to introduce Ilkka Suhonen, partner and a member of our advisory team. He has been actively helping us not just to plan our recruitment but also business development in general!',
    date: '2020-11-30T12:30:00',
    authorId: 6,
    tags: ['sales', 'advisor', 'team'],
    categories: ['business'],
    content: (
      <div>
        <p className="f_400 mb-30">
          The strength of Polar Night Software comes from people. This time we are introducing Ilkka Suhonen, partner
          and advisor.
        </p>
        <p className="f_400 mb_40">
          <span className="f_600">
            1. Tell us briefly about your background and what you want to bring to Polar Night Software?
          </span>
          <br />
          The strongest professional competence I have in the recruiting world, where I got to work approximately ten
          years as a consultant, in Finland’s most rapidly growing IT consulting company Nordcloud and in the media
          industry at Sanoma. Along the way, I have accumulated experience also from sales, marketing, management and
          entrepreneurship. Successful recruitment is at the heart of Polar Night, and I want to reinforce that.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">2. What made you join the background forces?</span>
          <br />A passionate interest in the industry, top team and a desire to be involved in creating a new service
          for the market. Above all, I want to be involved in creating a service that I could buy myself!
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">3. Why do you believe in the potential of Polar Night Software?</span>
          <br />
          Polar Night's potential begins with a top-notch team (Henri Jääskeläinen, Piotr Chojnacki, Mateusz
          Wojciechowski, Anssi Pakkanen and Antti Luostarinen), on which it is good to build. Investments in strong
          basic work and service, as well as in the recruitment of experienced experts, create a solid foundation for
          Polar Night. Although the company is still in its infancy, it already has several satisfied customers and
          excellent references. Moreover, Polar Night’s way of operating and serving customers could not be better
          suited to the present and the future.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">4. How do you see the future/opportunities of Polar Night?</span>
          <br />
          The industry needs renewal and Polar Night will bring it. After 5 years, Polar Night Software is a
          medium-sized and still growing IT consulting firm known for its excellent service and hardcore experts.
        </p>
      </div>
    ),
  },
  {
    id: 14,
    language: languages.FINNISH,
    image: 'advisor-introduction-ilkka-suhonen.jpg',
    link: 'advisor-esittely-ilkka-suhonen',
    title: 'Advisor-esittely - Ilkka Suhonen',
    description:
      'Esittelyssä Ilkka Suhonen, partneri ja neuvonantaja. Ilkka on vahvalla kokemuksellaan ja energisyydellään auttanut meitä suunnittelemaan rekrytointiamme, sekä liiketoimintaamme yleensä!',
    date: '2020-11-30T12:30:00',
    authorId: 6,
    tags: ['myynti', 'advisor', 'tiimi'],
    categories: ['liiketoiminta'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Polar Night Softwaren vahvuus tulee ihmisistä. Tällä kertaa esittelyssä Ilkka Suhonen, partneri ja
          neuvonantaja.
        </p>
        <p className="f_400 mb_40">
          <span className="f_600">
            1. Kerro tiiviisti omasta taustasta ja mitä haluat tuoda Polar Night Softwaren toimintaan?
          </span>
          <br />
          Vahvin ammatillinen osaaminen minulla on rekrytointien maailmasta, jossa olen saanut työskennellä noin
          kymmenen vuotta konsulttina, Suomen nopeimmin kasvaneessa IT-konsulttiyrityksessä Nordcloudilla ja
          media-alalla Sanomilla. Matkan varrella olen kerännyt kokemusta talteen myös myynnistä, markkinoinnista,
          johtamisesta ja yrittämisestäkin. Polar Nightin tekemisen keskiössä ovat onnistuneet rekrytoinnit ja tätä
          haluan olla omalta osaltani vahvistamassa.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">2. Mikä sai lähtemään mukaan taustavoimiin?</span>
          <br />
          Palava kiinnostus toimialaa kohtaan, huipputiimi ja halu olla mukana luomassa uutta palvelua markkinaan. Ennen
          kaikkea haluan olla mukana luomassa palvelua, jota voisin itsekin ostaa!
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">3. Miksi uskot Polar Night Softwaren potentiaaliin?</span>
          <br />
          Polar Nightin potentiaali lähtee timanttisesta tiimistä (Henri Jääskeläinen, Piotr Chojnacki, Mateusz
          Wojciechowski, Anssi Pakkanen ja Antti Luostarinen), jonka päälle on hyvä rakentaa. Panostukset vahvaan
          perustekemiseen ja palveluun sekä kokeneiden osaajien rekrytointiin luovat tukevan pohjan Polar Nightille.
          Vaikka yritys on vielä alkutaipaleella, on sillä jo useita tyytyväisiä asiakkaita ja erinomaisia referenssejä.
          Lisäksi Polar Nightin tapa toimia ja palvella asiakkaita ei voisi sopia paremmin tähän hetkeen ja
          tulevaisuuteen.
        </p>
        <p className="f_400 mb-30">
          <span className="f_600">4. Millaisena näet Polar Nightin tulevaisuuden/mahdollisuudet?</span>
          <br />
          Ala kaipaa uudistumista ja sitä Polar Night tulee tuomaan. Polar Night Software on 5 vuoden kuluttua
          keskisuuri ja edelleen kasvussa oleva, erinomaisesta palvelusta ja kovista osaajistaan tunnettu
          IT-konsulttitoimisto.
        </p>
      </div>
    ),
  },
  {
    id: 15,
    language: [languages.ENGLISH, languages.FINNISH],
    image: '8-considerations-for-api-design.jpg',
    link: '8-considerations-for-api-design',
    title: '8 considerations for API design',
    description:
      'During my career as a developer, I’ve touched many different systems that offered an external Web API. It’s not surprising these days - with the current direction that technology is headed in, it’s unlikely that we are going to return to isolated, local software which doesn’t need to communicate with other applications. Web APIs remain some of the more common types of web services.',
    date: '2021-04-15T11:00:00',
    authorId: 7,
    tags: ['software development', 'design', 'backend'],
    categories: ['software development'],
    content: (
      <div>
        <p className="f_400 mb-30">
          During my career as a developer, I’ve touched many different systems that offered an external Web API. It’s
          not surprising these days - with the current direction that technology is headed in, it’s unlikely that we are
          going to return to isolated, local software which doesn’t need to communicate with other applications. Web
          APIs remain some of the more common types of web services.
        </p>
        <p className="f_400 mb-30">
          My expertise lies with REST (or, technically, RESTful) APIs, and that is what I’d like to focus on. Because
          throughout the years, I’ve seen many systems make the same mistakes, that we then needed to fix later on.
        </p>
        <p className="f_400 mb-30">
          I will not be focusing on any particular language, or technology - those are simply design considerations to
          make, as you are working on your system. Things that ensure whoever integrates with your API will not hate you
          for the entire duration of the project.
        </p>
        <p className="f_400 mb-30">
          <ol className="big-markers">
            <li className="mb_20">
              <h5>Just use JSON</h5>
              <p className="f_400 mb-30">
                At this point, it really shouldn’t be a surprise that JSON is being pushed pretty much everywhere. It’s
                lightweight, it is supported by almost every framework out there, and it’s fast to encode and decode, on
                top of being relatively readable for a human. At this point, I would argue that there is no point
                fighting JSON. It is the industry standard, and not using it is doing a disservice to everyone involved
                in your API - both the developers and the users.
              </p>
              <p className="f_400 mb-30">
                I have had some experiences with APIs that relied on XML responses, and we very quickly ran into parsing
                issues that needed to be resolved by the developers and successfully wasted hours upon hours on what was
                otherwise a very simple integration.
              </p>
            </li>

            <li className="mb_20">
              <h5>Control your return codes</h5>
              <p className="f_400 mb-30">
                Official HTTP Status Codes registry is maintained by IANA, and currently offers 62 officially registered
                codes, with some custom ones being put in use by service providers like AWS or Cloudflare. It’s - quite
                frankly - a lot. And nobody can force you to use all of the codes. Some of them will most likely never
                be applicable to your system.
              </p>
              <p className="f_400 mb-30">
                But that’s exactly the point. Sit down, figure out which of the codes will be applicable in the system,
                and don’t be afraid to use them. There is no inherent benefit to limiting yourself only to 200, 400 and
                500 return codes being used. You are not saving any resources - you are only confusing the end-user of
                your API more than is necessary.
              </p>
              <p className="f_400 mb-30">
                Even with a basic CRUD controller, you can easily use <strong>201</strong> (Created),{' '}
                <strong>204</strong> (No content), <strong>404</strong> (Not found), and <strong>409</strong> (Conflict)
                response codes. And this list only grows more expansive as you build your API out.
              </p>
              <p className="f_400 mb-30">
                The second part of this, however, isn’t just to use as many return codes as is possible -{' '}
                <strong>
                  use as many codes as you need, but don’t force yourself to make use of every single one.
                </strong>{' '}
                And what is perhaps more important - be sure to keep consistent in the way you use them. If you decide
                one DELETE returns 204 - they should all return 204. If your POST returns 201 - do it everywhere as
                well. Don’t force your user to look up the documentation for every single method before they use it.
              </p>
              <ol className="big-markers">
                <li className="mb_20">
                  <h5>Bonus entry 1: Learn the difference between 401 and 403</h5>
                  <p className="f_400 mb-30">
                    Probably the most common, and one of the more annoying things I encountered while working with APIs
                    is the confusion between those two codes. I partially understand why.{' '}
                    <strong>401 Unauthorized</strong> and <strong>403 Forbidden</strong> sound very similar
                    semantically. Especially if English is not your native language. But this small thing has led to
                    many hours of pointless debugging.
                  </p>
                  <p className="f_400 mb-30">
                    <strong>401 Unauthorized</strong> is an error code for authentication error. As in - the credentials
                    that you provided for your request could not be used to log in. Therefore, your request never fully
                    entered the system.
                  </p>
                  <p className="f_400 mb-30">
                    <strong>403 Forbidden</strong> means your authentication was successful, but whatever resource you
                    were trying to access wasn’t accessible with your credentials. I.e. you tried to access the account
                    details of another user, or resource with limited access.
                  </p>
                </li>

                <li className="mb_20">
                  <h5>Bonus entry 2: Learn the difference between 200 and 204</h5>
                  <p className="f_400 mb-30">
                    This is a pet-peeve more than anything else. And to be more specific, it is referring to learning
                    the difference between <strong>200</strong> (Success) with no content and{' '}
                    <strong>204 No content</strong>.
                  </p>
                  <p className="f_400 mb-30">
                    The major difference between the two response codes is that <strong>204</strong> isn’t supposed to
                    return anything - i.e. in case of DELETE request.
                  </p>
                  <p className="f_400 mb-30">
                    <strong>200</strong> with no content, as seen, for example, during getting a filtered collection,
                    means that there could be a return value - but in this case, the list that satisfies your
                    limitations is empty.
                  </p>
                </li>
              </ol>
            </li>

            <li className="mb_20">
              <h5>Confirm what the user has done</h5>
              <p className="f_400 mb-30">
                “But that’s what the return codes are for!” you may say. And you’d be absolutely correct. But this is
                about something more than just the return code. The user inputs a body and sends a POST request. The
                object is created, we get a 200 response… And what happens next? How does he know where to find the
                thing he has created? Well, naturally, we will need to find the ID of it, and then we can freely operate
                on it.
              </p>
              <p className="f_400 mb-30">
                But why waste time on it, if we could just return the ID right away? We already know what it is, just
                put it in the response body, and you are good to go.
              </p>
              <p className="f_400 mb-30">
                Similarly, when you are using a PUT or PATCH method you may run into some fields which are automatically
                updated, or generated as part of the modification process. If that’s the case - return those fields in
                the response, so that the user has an idea of the exact form the object is now in. Or, perhaps, is it
                even worth returning the entire object after it has been modified? That varies based on many aspects,
                but don’t discard that possibility. Your user might thank you.
              </p>
            </li>

            <li className="mb_20">
              <h5>Filtering, sorting, pagination</h5>
              <p className="f_400 mb-30">
                Not to be That Guy, but if you think your system will not need those functionalities, you are wrong.
                I’ve once made that mistake and just assumed “Oh, this system is too small, we will never need to
                paginate results on this collection”. Trying to fix that mistake when everything has already gone to
                production costs me a lot of time and nerves.
              </p>
              <p className="f_400 mb-30">
                Your database will grow, and eventually, it will be simply too much to return all the results. Even if
                you already filter out the resources your user is not allowed to see, it will simply become too much
                data to be passed back and forth, as time goes on.
              </p>
              <p className="f_400 mb-30">
                As a personal recommendation, I would recommend creating a generic pagination and sorting handler, and
                attach it to every GET method which can possibly make use of it. If it does nothing, you can always
                ignore it on the back-end. But that way, you can ensure consistency between methods, and make it easier
                for your end-users to figure out.
              </p>
              <p className="f_400 mb-30">
                <strong>Bonus hint:</strong>
                <br />
                If you are offering a pagination, always return the total number of elements available. There is nothing
                as annoying as having to blindly throw requests until they finally return an empty collection.
              </p>
              <p className="f_400 mb-30">
                The exact form of passing pagination/sorting information is secondary, though I am quite partial to
                using QueryString for specifying what exactly you’d like to retrieve. It makes the semantics of your
                URIs much more concise and readable.
              </p>
              <p className="f_400 mb-30">
                Filtering can be a bit more compared to doing it in a generic way, and sometimes it’s better to simply
                not overwork yourself for something that might not be fully achievable.
              </p>
            </li>

            <li className="mb_20">
              <h5>Prepare for versioning</h5>
              <p className="f_400 mb-30">
                Sometimes we like to be optimistic and say ‘Oh, but our API will not change, we will just add optional
                fields and handle everything on the back-end’. And then reality comes around.
              </p>
              <p className="f_400 mb-30">
                An API is very rarely going to be completely stable. So prepare for it right away. Think of the way your
                API is going to be used, and decide if this is something you need to concern yourself with. If your API
                is exclusively there to feed your in-house front-end application? You can probably get away with
                planning releases in a way that the two go hand-in-hand. If you are allowing someone else to use that
                API for integrations, however, there is a chance they are not going to be happy with you telling them
                ‘Hey, this endpoint is now gone, use another one’. They are going to be even less happy if they aren’t
                told at all.
              </p>
              <p className="f_400 mb-30">
                What’s the strategy for versioning? Does a request default to the newest version unless told otherwise?
                Or is there a configurable fallback? Do you want to have one version number, or are you going to split
                it between major numbers (for breaking changes) and minor revisions (for backward-compatible changes)?
              </p>
              <p className="f_400 mb-30">
                <strong>Bonus hint:</strong>
                <br />
                Think of how you want to handle a new version when it comes in. Are you going to include the version
                number in your URL? Or would you rather have it be part of the header. Perhaps a mixed approach works
                best - major version in the url and revision id in the header?
              </p>
              <p className="f_400 mb-30">But, once again, something that only you can decide.</p>
            </li>

            <li className="mb_20">
              <h5>Be ready for errors</h5>
              <p className="f_400 mb-30">
                It’d be really nice to have a system that will never produce an error, but those things will always
                happen, sooner or later. You should think about them from the very start of your work on the API.
              </p>
              <p className="f_400 mb-30">
                How are you going to return the error? What information is going to be displayed to the user, and how?
                What return codes are we planning to use, and how are we going to go further into detail.
              </p>
              <p className="f_400 mb-30">
                There isn’t a single ‘good’ answer here, but you need to consider all of them. Do you want to throw a
                full error message directly to the user? If you return too much data, you are exposing the structure of
                your system, and possibly opening yourself up to attacks. But if you return nothing, you are giving the
                customer no choice but to contact you and start asking questions. You are forcing someone to go and
                check exactly what went wrong manually. There is a balance to be struck. A balance that you need to
                achieve, with help of some cost-benefit considerations, and then put into a standardized response body
                used for errors.
              </p>
              <p className="f_400 mb-30">
                If you are intending on introducing internal error codes - great. But make sure that they can be easily
                correlated to… well, reality. Have some sort of documentation that explains exactly how one would read
                those codes in a meaningful way. Perhaps include a link to your help documentation right away.
              </p>
              <p className="f_400 mb-30">
                What I am saying is - Have a plan on how to handle an error, before errors start popping up.
              </p>
            </li>

            <li className="mb_20">
              <h5>Make it human-readable</h5>
              <p className="f_400 mb-30">
                A web API is a curious case, where despite being a programming tool, it works best when the URLs you
                construct are almost grammatically correct. It isn’t strictly necessary, but the easier it is to
                understand your URL at a glance, the less likely that you are going to have someone doing the completely
                wrong thing.
              </p>
              <p className="f_400 mb-30">
                Think of your URL as a sentence. You have the verb given by the type of HTTP request, that is then
                applied to nouns and qualifiers. As a rule, try to stick to plural naming in nouns, but it isn’t
                strictly necessary - but never mix the two, as having irregular pluralization of terms might be your
                downfall.
              </p>
              <p className="f_400 mb_50">
                Back to the concept of making your API readable, let’s take a look at a few examples.
              </p>
              <p className="f_400 mb-30">
                <table>
                  <tr>
                    <td className="code">
                      <strong>GET</strong> /users/12
                    </td>
                  </tr>
                  <tr>
                    <td>Retrieve an entity from the list of users with ID 12. </td>
                  </tr>
                </table>
              </p>
              <p className="f_400 mb_50">
                <table>
                  <tr>
                    <td className="code">
                      <strong>GET</strong> /users/12/messages
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Retrieve an entity from the list of users with ID 12, then return all messages related to it
                    </td>
                  </tr>
                </table>
              </p>
              <p className="f_400 mb-30">
                So, to reiterate a point - Build your URL like you would build a sentence. Avoid repetitions, avoid
                confusing your target. Have a concise, and clear structure.
              </p>
              <p className="f_400 mb-30">
                And as a few pointers:
                <ul>
                  <li>
                    Do not repeat verbs - If your method is already a GET, don’t put that in the name; If it’s a POST,
                    don’t write ‘update’. It’s already there. If the operation doesn’t accurately describe what you are
                    doing, you’ve chosen the wrong HTTP operation.
                  </li>
                  <li>
                    When you are requesting an entity, stick to verbs and identifiers. What are you asking for, and how
                    can we specify which exact one you are after?
                  </li>
                  <li>Only add extra verbs if you are triggering an action.</li>
                </ul>
              </p>
            </li>

            <li className="mb_20">
              <h5>Document, document, document</h5>
              <p className="f_400 mb-30">
                Yeah, I know. All developers hate creating documentation because it’s a waste of time. Why bother, if
                your entire team knows what’s going on, right?
              </p>
              <p className="f_400 mb-30">
                Well, mostly because your team is not going to stay the way it is forever. Someone might quit. Someone
                might be sick. And the rest of the team are going on their vacations. Suddenly, you are leaving one dev
                with no experience to understand everything. But it’s fine, your code is self-documenting and there are
                absolutely no exceptions to that rule, right?
              </p>
              <p className="f_400 mb-30">
                What about the clients, then. The thing about an API is that it’s not just used internally. You have
                other people making use of it. Is it your front-end team? Is it the client’s development team? Or maybe
                it’s the end-user directly? Either way, don’t leave them space to wonder. Just give them all the
                information they need to use the API the exact way it’s intended. There are great solutions out there,
                such as Swagger which take the brunt of the load of documenting your API, but there are other factors
                you need to keep in mind as well.
              </p>
              <p className="f_400 mb-30">
                Did you decide to introduce internal error codes? Prepare a document that lists all of them and explains
                how to find the cause. Do you have any return codes or return models that are different from the
                standards? Point them out explicitly.
              </p>
              <p className="f_400 mb-30">
                TL;DR? Don’t hide things. And document everything, even if you think it’s self-evident.
              </p>
            </li>
          </ol>
        </p>
      </div>
    ),
  },
  {
    id: 16,
    language: [languages.ENGLISH, languages.FINNISH],
    image: 'graph-database-intro.jpg',
    link: 'graph-database-intro',
    title: 'Graph database intro',
    description:
      'Due to some personal needs, I have recently started learning about graph databases, and I find the topic rather interesting. As such, I decided to write a quick summary of what really is a graph database, and why even consider using one. This is by no means an exhaustive list, merely a quick introduction to the topic. And if you are already familiar with the general concepts of databases, feel free to skip through the initial part.',
    date: '2022-01-12T10:15:00',
    authorId: 7,
    tags: ['software development', 'design', 'backend'],
    categories: ['software development'],
    content: (
      <div>
        <p className="f_400 mb-30">
          Due to some personal needs, I have recently started learning about graph databases, and I find the topic
          rather interesting. As such, I decided to write a quick summary of what really is a graph database, and why
          even consider using one.
        </p>
        <p className="f_400 mb-30">
          This is by no means an exhaustive list, merely a quick introduction to the topic. And if you are already
          familiar with the general concepts of databases, feel free to skip through the initial part.
        </p>
        <h5>Two words about databases</h5>
        <p className="f_400 mb-30">
          The primary purpose of nearly any application out there is to interact with data that comes from the users.
          This interaction might be completely obvious or obfuscated by layers of abstraction. Either way, at the end of
          the day, you will end up wanting to save that information somewhere. In some cases, memory storage will be
          enough. Or perhaps some files. But in most cases - especially when it comes to enterprise solutions - you will
          end up using a database.
        </p>
        <p className="f_400 mb-30">
          What is it? A database is a system that organizes data in a way that makes it easy to be accessed and
          modified. Each database has its logical structure, which defines the logic that drives it, and controls its
          behavior. They are used to collect and store data, which can be then organized and retrieved whenever needed.
          Both in business uses, and in the development of applications.
        </p>
        <p className="f_400 mb-30">
          There are a few classifications you could use to categorize different types of databases, but for the purposes
          of this article, we will be focusing on one, particular split. According to the data type.
        </p>
        <p className="f_400 mb-30">
          <ol className="big-markers">
            <li className="mb_20">
              <h5>Relational databases</h5>
              <p className="f_400 mb-30">
                If you have ever heard of a database, this is most likely the kind you are thinking of. It’s the kind of
                storage that uses a predefined schema, which serves as the blueprint for structure of data stored
                within.
              </p>
              <p className="f_400 mb-30">
                They are categorized into a set of tables, in which each column represents a data category, and each row
                is its own entry. Rows are identified by unique keys, which can then be used to relate entries to one
                another through operations such as joins. The structure is the key aspect here.
              </p>
              <p className="f_400 mb-30">
                Each table can only represent one kind of data. If you create a table for Customers, it can only contain
                information about a customer. If you create a table for Prices - it can only contain prices. Trying to
                mix and match data will be impossible without breaking several constraints enforced by the system.
              </p>
              <p className="f_400 mb-30">
                Despite that, however, relational databases have grown to be the de-facto standard in most situations.
                There are many popular relational database management systems out there, such as MSSQL, MySQL, OracleDB,
                IBM DB2 and more.
              </p>
              <p className="f_400 mb-30">
                Most of the technologies used in creation and day-to-day operations on relational databases are mature
                and well developed, with teams of developers perfecting the solutions over decades of development. They
                are a great solution for data integrity, security and efficiency. Because of their popularity, they are
                also very easy to get started with.
              </p>
            </li>

            <li className="mb_20">
              <h5>Non-relational (NoSQL) database</h5>
              <p className="f_400 mb-30">
                This category is far more difficult to describe in a concise paragraph. Why? Because the biggest aspect
                that makes a management system non-relational is the fact that, well, it doesn’t fall into the other
                category. Whereas relational databases enforce structure, non-relational databases rely on the
                simplicity of design and flexibility.
              </p>
              <p className="f_400 mb-30">
                Instead of relying solely on tables, non-relational databases use more abstract ways of representing
                data. They allow for an individual data point to vary from the overall schema. If you’d like to store
                three different kinds of objects in a single collection - it’s very much possible. This allows for an
                atypical structure of data, which uniquely fits the needs of any given implementation. Similarly, it
                also allows you to potentially change the data structure on the fly.
              </p>
              <p className="f_400 mb-30">
                This is the kind of agility which greatly speeds up the development of your solution, making it much
                easier to expand, without a need for data migrations or transformations.
              </p>
              <p className="f_400 mb-30">
                But that is not the greatest benefit of NoSQL databases. They came into the public view more recently as
                a result of the explosive growth of cloud computing. By their nature, relational databases struggle with
                expansion - they rely on structure, meaning that the only way of improving the capacity of your system
                is to add more power. NoSQL databases, however, are usually built with scalability in mind. They can be
                distributed across a cluster of computers, thanks to the wide adoption of eventual consistency.
              </p>
              <p className="f_400">There are a few types of NoSQL databases we can distinguish:</p>
              <ul className="big-markers">
                <li className="mb_20">
                  <p className="f_400">
                    <strong>Key-value databases</strong> are likely the simplest type of databases, with each item
                    containing only two fields - key and value. They are often used as cache solutions.
                  </p>
                </li>

                <li className="mb_20">
                  <p className="f_400">
                    <strong>Document databases</strong> store information in documents similar to JSON objects. They
                    rely on entities that can vary in structure, and can be queried for different parts of it. An
                    example use for them is storing articles on a website.
                  </p>
                </li>

                <li className="mb_20">
                  <p className="f_400">
                    <strong>Column-oriented databases</strong> store and manage data in multiple columns, each of which
                    acts as a record. They are used to store massive amounts of data and query through them.
                  </p>
                </li>

                <li className="mb_20">
                  <p className="f_400">
                    <strong>Graph databases</strong> represent data in the form of nodes and edges. This type thrives
                    when it comes to identifying connections between data. But… I will be talking about them more in
                    just a second.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </p>
        <h5>What's a 'graph database'</h5>
        <p className="f_400 mb-30">
          After this lengthy introduction, we can finally get to the main purpose of this article. I have recently
          started reading more about graph databases and I find them thoroughly interesting as an alternative to more
          traditional models of storing data.
        </p>
        <p className="f_400">
          According to its mathematical definition, a graph is a set of objects, represented as <strong>nodes</strong>{' '}
          and connections between them - represented as <strong>edges</strong>. Edges within the graph can be
          undirected, or only connect nodes in one direction. In mathematics, they are used to model pairwise relations
          between objects.
        </p>
        <img className="img-centered" src={require('../../img/articles/graph-database-intro-img-1.png')} alt="" />
        <p className="f_400 mb-30">
          In the context of mathematics, the graph usually represents the relations, sometimes described with a single,
          numerical value. An example of this can be seen in the (ever so cliche) Travelling Salesman Problem. Luckily,
          we won’t have to be dealing with this one.
        </p>
        <p className="f_400">
          In IT, the uses of a graph are similar in nature. We are still fundamentally modeling relationships between
          two nodes. In our case, however, both nodes and edges will most likely be described by more data, than just a
          singular number.
        </p>
        <img className="img-centered" src={require('../../img/articles/graph-database-intro-img-2.png')} alt="" />
        <p className="f_400 mb-30">
          In a typical graph database, every node will be categorized with a label, which describes its type (for
          example - a person, or a movie), and a collection of properties, represented as key-value pairs. Edges, which
          represent relationships, are usually described with label, direction and they can also have a set of key-value
          properties.
        </p>
        <p className="f_400 mb-30">
          Sounds good. But at this point, you might ask - “Sure, I get it. But I can do all of that in a relational
          database as well. Why bother?” And at the face value, you are completely right. Simple relations between - for
          example - people can be easily represented with a link table and call it a day. But what about relationships
          that are not as direct? Well, naturally - you start creating more joins, denormalizing data that you store,
          and start compromising on the benefits of your database just to get the result that is needed. Most likely,
          because your business required you to do so.
        </p>
        <p className="f_400 mb-30">
          Due to the nature of a relational database, it’s great at answering queries that we have expected to be
          requested - because our schema will be built in a way to support that. As an example: We are building a shoe
          store using RDBMS. We are expecting all the typical functionalities. Can your system find the most popular
          product? Easily. Can it tell me how much an average customer spends per year? Sure can. Now, can you tell me
          how many users of the store who have bought at least 3 pairs of sports shoes live in Warsaw and used a
          discount code at least once in the past 3 months? The answer is still… Probably? But with every added
          qualifier, the number of joins will be growing and growing, making your query less performant.
        </p>
        <p className="f_400 mb-30">
          And this is the kind of situation where a graph database will shine. Answering unexpected, odd questions about
          how our data relates to itself. Due to how graph traversal algorithms work, we can easily find out how two
          things are related. We simply take a look at what kind of edge comes out of our initial node and follow it.
          Naturally, this isn’t just some magical property we are taking advantage of. It’s all a result of the way that
          engines are designed and optimized.
        </p>
        <p className="f_400 mb-30">
          Graph databases are made to work with exploring relationships - as opposed to <strong>relational</strong>{' '}
          databases, ironically enough.
        </p>
        <h5>Benefits and uses of the graph</h5>
        <p className="f_400 mb-30">
          Okay, so the basic idea is that graph databases represent data as a set of nodes and relations between them.
          They are better for using those relations to connect data. There are benefits in terms of performance,
          whenever traversing a complex graph. It has more flexibility, as to how we store our data. It allows us to
          change things on the fly. But what’s the real-life use of a database like this?
        </p>
        <ul className="big-markers">
          <li className="mb_20">
            <p className="f_400">
              <strong>Recommendation engines</strong> - You’ve probably seen them around the web multiple times -
              “Customers also bought this”, or “You might like this movie”. The terms used around the web to give the
              illusion that the website knows you better than you know yourself. But at the same time, valuable tools to
              improve visitor to customer ratio in case of many stores. It is, naturally, possible to make them work in
              a relational database, but using a graph to represent customers, products and connections between them,
              with optimized algorithms to find correlation greatly improves the performance of those solutions, and
              ensures that the website can give it’s user strong suggestions based on a multitude of data points with
              minimal delays.
            </p>
          </li>

          <li className="mb_20">
            <p className="f_400">
              <strong>Social media fraud detection</strong> - Love them, or hate them - social media has now firmly
              entrenched into the modern way of life. Facebook, Instagram and Twitter have taken a key role in overall
              public debate, boiling down complex issues to two-line comments on the web. There are many bad actors that
              try to take advantage of that ecosystem. Be that with selling followers, or distributing malicious
              content. Many aspects of those behaviours can be easily identified with graph processing, used to map
              typical patterns of behaviours and identify outliers. Examples of Facebook’s social graph are readily
              available online, should you be interested in more detail
            </p>
          </li>

          <li className="mb_20">
            <p className="f_400">
              <strong>Contact Tracing</strong> - Though not a new concept, we’ve recently seen a surge in interest in
              this concept when the COVID pandemic started. Finding out individuals in a population who had contact with
              other people and could have been infected with the virus became a talking point (despite largely
              unsuccessful attempts at introducing it at its fullest). This is another example of a perfect use for the
              graph database. We have a clear mapping of people (nodes) and others who they had contact with, as edges.
              With a sufficiently large data set, it allows to not only find people who could have been infected, but
              also map a potential spread of a virus or a disease.
            </p>
          </li>

          <li className="mb_20">
            <p className="f_400">
              <strong>Complex manufacturing processes</strong> - Though ‘relationships’ are often understood in human
              terms, this isn’t the only context in which they can be used. When it comes to complex machines, like
              cars, they can consist of thousands upon thousands of parts that come from different sources, have
              different manufacturing time, and can be affected by different circumstances. Using a graph database
              allows us to keep track of those relationships and act accordingly. Both in terms of the supply chain
              (possible disruptions, problems, price changes) and in terms of assembly (which parts are blocked by
              others, etc.)
            </p>
          </li>
        </ul>
        <p className="f_400 mb-30">
          In case it wasn’t obvious, I am not an expert in any of those categories, those are merely examples based on
          actual use-cases described by many organizations. If you’d like to learn more, there are resources out there
          that will help you get a better look at the use-cases for this.
        </p>
      </div>
    ),
  },
];

const articlesData = () => articles.sort(({ date: dateA }, { date: dateB }) => parseISO(dateB) - parseISO(dateA));

export default articlesData;
