import React, { useLayoutEffect } from 'react';

const ClutchWidget = () => {
  useLayoutEffect(() => {
    if (window.CLUTCHCO && !window.CLUTCHCO.loaded) {
      window.CLUTCHCO.Init();
    }
  }, []);

  return (
    <div className="clutch-widget-outer-container">
      <div className="clutch-widget-container">
        <div
          className="clutch-widget"
          data-url="https://widget.clutch.co"
          data-widget-type="12"
          data-height="375"
          data-nofollow="true"
          data-expandifr="true"
          data-scale="100"
          data-reviews="275670,163927,105081,65927"
          data-clutchcompany-id="1525394"
        ></div>
      </div>
    </div>
  );
};

export default ClutchWidget;
