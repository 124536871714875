import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { generatePath } from 'react-router';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { useTranslation } from 'react-i18next';

const CarouselItem = ({ item, match }) => {
  const { t, i18n } = useTranslation();
  const imgName = item.imgs[0];
  const description = item.shortDescription;

  const projectPath = () => {
    const { link } = item;
    const path = generatePath(match.path, { portfolioLink: link, locale: i18n.language });
    const localizedPath = getLocalizedPath({ i18n, path });
    return localizedPath;
  };

  return (
    <Link to={`${projectPath()}#portfolio-item`}>
      <div className="carousel-item">
        <div className="gap">
          <div className="img-box">
            <img src={require(`../../img/portfolio-details-small/${imgName}`)} alt={item.title} className="img" />
            <div className="description-bg">
              {description ? (
                <span className="description">{t(description)}</span>
              ) : (
                <span className="arrow">
                  <i className="ti-arrow-right"></i>
                </span>
              )}
            </div>
          </div>
          <div className="title-box">
            <span className="title f_600 f_size_16 f_p">{item.title}</span>
            <span className="dash">|</span>
            <span className="type">{item.projectType}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CarouselItem;
