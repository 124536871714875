import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RecoilRoot } from 'recoil';

import './assets/themify-icon/themify-icons.css';
import './assets/simple-line-icon/simple-line-icons.css';
import './assets/elagent/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/main.css';
import './assets/responsive.css';
import './assets/polar-night-main.css';
import './assets/polar-night-responsive.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const loader = document.querySelector('#preloader');
const hideLoader = () => loader.classList.add('loader--hide');

const root = createRoot(document.getElementById('root'));
root.render(
  <RecoilRoot>
    <App hideLoader={hideLoader} />
  </RecoilRoot>
);

serviceWorker.unregister();
