import { languages } from '../constants/languages';

export const getLanguageFromPath = (path) => {
  const supportedLanguages = Object.values(languages);
  for (const lang of supportedLanguages) {
    if (path.startsWith(`/${lang}/`) || path === `/${lang}`) {
      return lang;
    }
  }
  return undefined;
};
