import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ArticlesComponent from '../components/Articles/Articles';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import OnepageMenu from '../components/OnepageMenu';
import { useTranslation } from 'react-i18next';

const Articles = () => {
  const { t } = useTranslation();

  return (
    <div className="body_wrapper allow-sticky">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="articles-bg.jpg"
        Ptitle={t('ARTICLES')}
        Pdescription={t('ARTICLES_DESC')}
      />
      <ArticlesComponent />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default Articles;
