import React from 'react';
import { useTranslation } from 'react-i18next';

const Office = () => {
  const { t } = useTranslation();

  return (
    <section className="prototype_service_info our-office">
      <div className="symbols-pulse active">
        <div className="pulse-1"></div>
        <div className="pulse-2"></div>
        <div className="pulse-3"></div>
        <div className="pulse-4"></div>
        <div className="pulse-x"></div>
      </div>
      <div className="container">
        <h2 className="f_size_30 f_600 t_color3 l_height45 text-center wow fadeInUp" data-wow-delay="0.1s">
          {t('OUR_OFFICE')}
        </h2>
        <div className={`row h_analytices_features_item align-items-center`}>
          <div className="col-lg-6">
            <div className="h_security_img text-center">
              <img
                width="100%"
                className="wow fadeInUp"
                src={require('../img/our-office.jpg')}
                alt="How we work at Polar Night Software"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h_analytices_content">
              <p className="wow fadeInUp" data-wow-delay="0.3s">
                {t('OUR_OFFICE_DESCRIPTION')}
              </p>
              <ul className="list-unstyled">
                <li className="wow fadeInUp" data-wow-delay="0.5s">
                  <span>{t('OUR_OFFICE_1_TITLE')}:</span> {t('OUR_OFFICE_1_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.7s">
                  <span>{t('OUR_OFFICE_2_TITLE')}:</span> {t('OUR_OFFICE_2_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.9s">
                  <span>{t('OUR_OFFICE_3_TITLE')}:</span> {t('OUR_OFFICE_3_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="1.1s">
                  <span>{t('OUR_OFFICE_4_TITLE')}:</span> {t('OUR_OFFICE_4_DESC')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Office;
