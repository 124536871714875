import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { phoneRegExp } from '../../shared/constants/phoneRegExp';
import { getBase64 } from '../../shared/functions/getBase64';
import get from 'lodash/get';

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;
const RECAPTCHA_SITE_KEY = '6Le9NF4dAAAAAPKS-_1_3JMHSH2eJepm-bUwAZNH';

const applyFormStates = {
  FORM_VISIBLE: 'FORM_VISIBLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const initialValues = {
  name: '',
  linkedin: '',
  email: '',
  phone: '',
  message: '',
  fileUploadText: '',
  fileUploadInput: null,
  recaptcha: '',
};

const maxFileSize = 5242880;

const applyFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(100).required(),
  linkedin: Yup.string().min(15, 'Wrong LinkedIn profile link').max(150, 'Wrong LinkedIn profile link'),
  email: Yup.string().email().required(),
  phone: Yup.string().matches(phoneRegExp, 'invalid phone format').required(),
  message: Yup.string().min(5).max(5000),
  fileUploadInput: Yup.mixed()
    /*.test('fileExistence', 'CV file is required', (value) => value)*/
    .test('fileSize', 'File too large', (value) => !value || (value && value.size <= maxFileSize))
    .test(
      'fileFormat',
      'Unsupported format',
      (value) => !value || ['pdf', 'docx'].includes(get(value, 'name', '').split('.').pop())
    ),
});

const JobPosition = ({ positionData }) => {
  const [formState, setFormState] = useState(applyFormStates.FORM_VISIBLE);
  const [fileUploadInputRef, setFileUploadInputRef] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation();
  const reCaptchaRef = React.createRef();

  const fileUploadInputRefChanged = (element) => setFileUploadInputRef(element);

  const updateUploadBoxData = (setFieldValueFunc, file, text) => {
    if (!file && fileUploadInputRef) {
      fileUploadInputRef.value = null;
    }
    setFieldValueFunc('fileUploadInput', file);
    setFieldValueFunc('fileUploadText', text);
  };

  const handleSubmit = (data) => {
    setIsPending(true);

    getBase64(data.fileUploadInput, (fileBase64) => {
      const headers = {
        'Content-Type': 'application/json',
      };

      const params = {
        headers,
        method: 'POST',
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          phone: data.phone,
          linkedin: data.linkedin,
          message: data.message,
          cvFileName: data.fileUploadText,
          cvContent: fileBase64,
        }),
      };

      fetch(`${URL}/apply`, params)
        .then((response) => {
          setFormState(response.ok ? applyFormStates.SUCCESS : applyFormStates.ERROR);
        })
        .catch(() => {
          setFormState(applyFormStates.ERROR);
        })
        .finally(() => {
          setIsPending(false);
        });
    });
  };

  const handleBlur = (e, props) => {
    if (!props.values.recaptcha) {
      reCaptchaRef.current.execute();
      props.setSubmitting(true);
    }
    props.handleBlur(e);
  };

  return (
    <section className="job-position-area sec_pad bg_color">
      <div className="container">
        <div className="row">
          <div className="apply_form col-12">
            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Application Form</h2>
            {formState === applyFormStates.FORM_VISIBLE && (
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={applyFormValidationSchema}
              >
                {(props) => {
                  return (
                    <Form className="apply_form_box form" id="applyForm">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <label htmlFor="name" className="required">
                              Full Name
                            </label>
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Your Full Name"
                              onBlur={(e) => handleBlur(e, props)}
                            />
                            <ErrorMessage name="name">{(message) => <p className="error">{message}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <label htmlFor="email" className="required">
                              E-mail
                            </label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Your E-mail"
                              onBlur={(e) => handleBlur(e, props)}
                            />
                            <ErrorMessage name="email">{(message) => <p className="error">{message}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <label htmlFor="phone" className="required">
                              Phone
                            </label>
                            <Field
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder="Your Phone"
                              onBlur={(e) => handleBlur(e, props)}
                            />
                            <ErrorMessage name="phone">{(message) => <p className="error">{message}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <label htmlFor="linkedin">LinkedIn Profile</label>
                            <Field
                              type="text"
                              id="linkedin"
                              name="linkedin"
                              placeholder="Your LinkedIn Profile"
                              onBlur={(e) => handleBlur(e, props)}
                            />
                            <ErrorMessage name="linkedin">
                              {(message) => <p className="error">{message}</p>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group upload">
                            <label htmlFor="cv">CV / Resume</label>
                            <div>
                              <input
                                id="file-upload"
                                name="file-upload"
                                ref={fileUploadInputRefChanged}
                                type="file"
                                onChange={(event) => {
                                  updateUploadBoxData(
                                    props.setFieldValue,
                                    event.currentTarget.files[0],
                                    get(event.currentTarget.files, '[0].name', '')
                                  );
                                }}
                              />
                              <button
                                name="cv"
                                id="cv"
                                className="btn_three col-xl-2 col-lg-3 col-md-4 col-sm-5 col-12 no-gutters"
                                disabled={props.isSubmitting}
                                onClick={() => fileUploadInputRef.click()}
                                type="button"
                              >
                                Add Your CV
                              </button>
                              <Field
                                type="text"
                                name="fileUploadText"
                                id="fileUploadText"
                                className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-12 no-gutters"
                                placeholder="We accept only .pdf and .docx files"
                                readOnly
                              />
                              <ErrorMessage name="fileUploadInput">
                                {(message) => <p className="error">{message}</p>}
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <label htmlFor="message">Comments</label>
                            <Field
                              component="textarea"
                              name="message"
                              id="message"
                              cols="30"
                              rows="7"
                              placeholder="Any comments?"
                              onBlur={(e) => handleBlur(e, props)}
                            />
                            <ErrorMessage name="message">
                              {(message) => <p className="error">{message}</p>}
                            </ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <p>
                            By clicking "Submit application" button, you agree to the processing of your personal data
                            contained in the application documents (CV and other documents) for the needs of recruitment
                            by the data administrator as stated in <a href="/privacy-policy">privacy policy</a>. You
                            declare that you provide your personal data voluntarily and that they are true.
                          </p>
                          <p>
                            You have right to access, modify and remove your data by contacting us at{' '}
                            <a href="mailto:contact@polarnightsoftware.com">contact@polarnightsoftware.com</a>
                          </p>
                        </div>
                      </div>
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        size="invisible"
                        onChange={(value) => {
                          props.setFieldValue('recaptcha', value);
                          props.setSubmitting(false);
                        }}
                      />
                      <button
                        type="submit"
                        className="btn_three col-lg-3 col-md-4 col-sm-5 col-12"
                        disabled={props.isSubmitting || isPending /*|| !get(values, 'fileUploadInput')*/}
                      >
                        {props.isSubmitting || isPending ? 'Sending...' : 'Submit Application'}
                      </button>
                    </Form>
                  );
                }}
              </Formik>
            )}
            {formState === applyFormStates.SUCCESS && (
              <div id="success">Your application was properly sent. We will get back to you as soon as possible.</div>
            )}
            {formState === applyFormStates.ERROR && (
              <div id="error">
                {t('SOMETHING_WENT_WRONG')}&nbsp;
                <span
                  className="try_again"
                  onClick={() => {
                    setFormState(applyFormStates.FORM_VISIBLE);
                  }}
                >
                  {t('TRY_AGAIN')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default JobPosition;
