import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { phoneRegExp } from '../shared/constants/phoneRegExp';
import ReCAPTCHA from 'react-google-recaptcha';

const URL = process.env.REACT_APP_SEND_MAIL_API_URL;
const RECAPTCHA_SITE_KEY = '6Le9NF4dAAAAAPKS-_1_3JMHSH2eJepm-bUwAZNH';

const subjects = {
  software: 'SOFTWARE_DEV_MAINTENANCE',
  nearshoring: 'NEARSHORE_CONSULTING',
};

const contactFormStates = {
  FORM_VISIBLE: 'FORM_VISIBLE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: contactFormStates.FORM_VISIBLE,
    };
    this.t = this.props.t;

    this.contactFormValidationSchema = Yup.object().shape({
      name: Yup.string(),
      email: Yup.string()
        .test('is-phone-filled', this.t('EITHER_PHONE_OR_EMAIL'), function (value) {
          const { phone } = this.parent;
          return !phone ? value != null : true;
        })
        .email(this.t('INVALID_EMAIL')),
      phone: Yup.string()
        .test('is-email-filled', this.t('EITHER_PHONE_OR_EMAIL'), function (value) {
          const { email } = this.parent;
          return !email ? value != null : true;
        })
        .matches(phoneRegExp, this.t('INVALID_PHONE')),
      subject: Yup.string(),
      message: Yup.string(),
    });

    this.initialValues = {
      name: '',
      email: '',
      phone: '',
      subject: (this.props.subject && this.t(subjects[this.props.subject])) || '',
      message: '',
      recaptcha: '',
    };

    this.reCaptchaRef = React.createRef();
  }

  handleSubmit(data) {
    const headers = {
      'Content-Type': 'application/json',
    };

    const params = {
      headers,
      method: 'POST',
      body: JSON.stringify(data),
    };

    fetch(`${URL}/send`, params)
      .then((response) => {
        this.setState({
          ...this.state,
          formState: response.ok ? contactFormStates.SUCCESS : contactFormStates.ERROR,
        });
      })
      .catch(() => {
        this.setState({ ...this.state, formState: contactFormStates.ERROR });
      });
  }

  handleBlur = (e, props) => {
    if (!props.values.recaptcha) {
      this.reCaptchaRef.current.execute();
      props.setSubmitting(true);
    }
    props.handleBlur(e);
  };

  render() {
    const t = this.t;
    return (
      <section className="contact_info_area sec_pad bg_color mt_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">{t('OFFICE_ADDRESS')}</h6>
                <p className="f_400 f_size_15">
                  ul. Sienkiewicza 85/87, floor 11th
                  <br />
                  90-318 Łódź, Poland
                </p>
              </div>
              <div className="contact_info_item">
                <h6 className="f_p f_size_20 t_color3 f_500 mb_20">{t('CONTACT_INFO')}</h6>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">Sales, Aku Kajan</span>{' '}
                  <a href="tel:358453216766">(+358) 45 321 6766</a>
                </p>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">{t('EMAIL')}:</span>{' '}
                  <a href="mailto:aku.kajan@polarnightsoftware.com">aku.kajan@polarnightsoftware.com</a>
                </p>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">{t('CONTACT')}</span> <a href="tel:48731407479">(+48) 731 407 479</a>
                </p>
                <p className="f_400 f_size_15">
                  <span className="f_400 t_color3">{t('EMAIL')}:</span>{' '}
                  <a href="mailto:contact@polarnightsoftware.com">contact@polarnightsoftware.com</a>
                </p>
              </div>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_20">{t('LEAVE_A_MESSAGE')}</h2>
              {this.state.formState === contactFormStates.FORM_VISIBLE && (
                <Formik
                  initialValues={this.initialValues}
                  onSubmit={this.handleSubmit.bind(this)}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={this.contactFormValidationSchema}
                >
                  {(props) => (
                    <Form className="contact_form_box" id="contactForm">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder={t('YOUR_EMAIL')}
                              onBlur={(e) => this.handleBlur(e, props)}
                            />
                            <ErrorMessage name="email">{(message) => <p className="error">{message}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group text_box">
                            <Field
                              type="text"
                              name="phone"
                              id="phone"
                              placeholder={t('YOUR_PHONE')}
                              onBlur={(e) => this.handleBlur(e, props)}
                            />
                            <ErrorMessage name="phone">{(message) => <p className="error">{message}</p>}</ErrorMessage>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group text_box">
                            <Field
                              component="textarea"
                              name="message"
                              id="message"
                              cols="30"
                              rows="10"
                              placeholder={t('ENTER_YOUR_MESSAGE')}
                              onBlur={(e) => this.handleBlur(e, props)}
                            />
                            <ErrorMessage name="message">
                              {(message) => <p className="error">{message}</p>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      <ReCAPTCHA
                        ref={this.reCaptchaRef}
                        sitekey={RECAPTCHA_SITE_KEY}
                        size="invisible"
                        onChange={(value) => {
                          props.setFieldValue('recaptcha', value);
                          props.setSubmitting(false);
                        }}
                      />
                      <button type="submit" className="btn_three" disabled={props.isSubmitting}>
                        {t('SEND_MESSAGE')}
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
              {this.state.formState === contactFormStates.SUCCESS && (
                <div id="success">{t('MESSAGE_SUCCESSFULLY_SENT')}</div>
              )}
              {this.state.formState === contactFormStates.ERROR && (
                <div id="error">
                  {t('SOMETHING_WENT_WRONG')}&nbsp;
                  <span
                    className="try_again"
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        formState: contactFormStates.FORM_VISIBLE,
                      });
                    }}
                  >
                    {t('TRY_AGAIN')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Contacts);
