const authorsData = [
  {
    id: 1,
    name: 'Henri Jääskeläinen',
    title: 'CEO',
    description: 'HENRI_JAASKELAINEN_SHORT_DESC',
    image: 'henri-jaaskelainen.jpg',
  },
  {
    id: 2,
    name: 'Mikko Kojo',
    title: 'Product Director, Sympa Oy',
    description: 'MIKKO_KOJO_SHORT_DESC',
    image: 'mikko-kojo.jpg',
  },
  {
    id: 3,
    name: 'Anssi Pakkanen',
    title: 'Advisor',
    description: 'ANSSI_PAKKANEN_SHORT_DESC',
    image: 'anssi-pakkanen.jpg',
  },
  {
    id: 4,
    name: 'Piotr Chojnacki',
    title: 'CTO',
    description: 'PIOTR_CHOJNACKI_SHORT_DESC',
    image: 'piotr-chojnacki.jpg',
  },
  {
    id: 5,
    name: 'Mateusz Wojciechowski',
    title: 'Co-founder / Tech Lead',
    description: 'MATEUSZ_WOJCIECHOWSKI_SHORT_DESC',
    image: 'mateusz-wojciechowski.jpg',
  },
  {
    id: 6,
    name: 'Ilkka Suhonen',
    title: 'Partner / Advisor',
    description: 'ILKKA_SUHONEN_SHORT_DESC',
    image: 'ilkka-suhonen.jpg',
  },
  {
    id: 7,
    name: 'Maciej Polak',
    title: 'Software Developer',
    description: 'MACIEJ_POLAK_SHORT_DESC',
    image: 'maciej-polak.jpg',
  },
];

export default authorsData;
