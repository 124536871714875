import React from 'react';
import { useTranslation } from 'react-i18next';

const HowWeWork = () => {
  const { t } = useTranslation();

  return (
    <section className="how-we-work">
      <div className="container">
        <h2 className="f_size_30 f_600 t_color3 l_height45 text-center wow fadeInUp" data-wow-delay="0.1s">
          {t('HOW_WE_WORK')}
        </h2>
        <div className={`row h_analytices_features_item align-items-center flex-row-reverse`}>
          <div className="col-lg-6">
            <div className="h_security_img text-center">
              <img
                width={600}
                className="wow fadeInUp"
                src={require('../img/how-we-work.jpg')}
                alt="How we work at Polar Night Software"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="h_analytices_content">
              <p className="wow fadeInUp" data-wow-delay="0.3s">
                {t('HOW_WE_WORK_DESCRIPTION')}
              </p>
              <ul className="list-unstyled">
                <li className="wow fadeInUp" data-wow-delay="0.5s">
                  <span>{t('HOW_WE_WORK_1_TITLE')}:</span> {t('HOW_WE_WORK_1_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.7s">
                  <span>{t('HOW_WE_WORK_2_TITLE')}:</span> {t('HOW_WE_WORK_2_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="0.9s">
                  <span>{t('HOW_WE_WORK_3_TITLE')}:</span> {t('HOW_WE_WORK_3_DESC')}
                </li>
                <li className="wow fadeInUp" data-wow-delay="1.1s">
                  <span>{t('HOW_WE_WORK_4_TITLE')}:</span> {t('HOW_WE_WORK_4_DESC')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
