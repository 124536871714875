import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Sticky from 'react-stickynode';
import { withTranslation } from 'react-i18next';
import { languages, languageLabels } from '../shared/constants/languages';
import getLocalizedPath from '../shared/functions/getLocalizedPath';

const availableLanguages = Object.values(languages);

class OnepageMenu extends Component {
  render() {
    const { forceScrolledState, mClass, nClass, cClass, slogo, hbtnClass, t, i18n } = this.props;
    const selectedLang = availableLanguages.includes(i18n.language) ? i18n.language : availableLanguages[0];
    const fixedClassName = 'navbar_fixed';
    return (
      <Sticky
        top={0}
        innerZ={9999}
        activeClass={forceScrolledState ? undefined : fixedClassName}
        className={forceScrolledState ? fixedClassName : undefined}
      >
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Link className={`navbar-brand ${slogo}`} to={getLocalizedPath({ i18n, path: '/' })}>
                <img src={require('../img/logo-polar-night-2.png')} alt="" />
                <img src={require('../img/logo-polar-night-3.png')} alt="logo" />
              </Link>
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item">
                    <Link className="nav-link" to={getLocalizedPath({ i18n, path: '/' })}>
                      {t('HOME')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={getLocalizedPath({ i18n, path: '/services' })}>
                      {t('SERVICES')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={getLocalizedPath({ i18n, path: '/portfolio' })}>
                      {t('PORTFOLIO')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={getLocalizedPath({ i18n, path: '/careers' })}>
                      {t('CAREERS')}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={getLocalizedPath({ i18n, path: '/articles' })}>
                      {t('ARTICLES')}
                    </Link>
                  </li>
                </ul>
                <Link className={`btn_get btn_hover ${hbtnClass}`} to={getLocalizedPath({ i18n, path: '/contact' })}>
                  {t('GET_IN_TOUCH')}
                </Link>

                <div className={'btn_language menu'}>
                  <div className={'dropdown submenu nav-item'}>
                    <a
                      data-toggle="dropdown"
                      href="#languages"
                      aria-expanded="false"
                      className={'dropdown-toggle'}
                      role="button"
                    >
                      <span className={'btn_language_label selected'}>{languageLabels[selectedLang]}</span>
                      <i className="ti-angle-down" />
                    </a>
                    <ul role="menu" className="dropdown-menu">
                      {availableLanguages
                        .filter((language) => language !== selectedLang)
                        .map((language) => (
                          <li className="nav-item" key={language}>
                            <Link
                              data-toggle="collapse"
                              to="#languages"
                              onClick={() => {
                                i18n.changeLanguage(language);
                              }}
                            >
                              <span className={'btn_language_label'}>{languageLabels[language]}</span>
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default withTranslation()(OnepageMenu);
