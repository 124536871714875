import React from 'react';
import Reveal from 'react-reveal/Reveal';
import testimonialsData from '../../shared/data/testimonialsData';
import { useTranslation } from 'react-i18next';

const ProjectTestimonial = ({ portfolioItem }) => {
  const { t } = useTranslation();
  const { testimonialId } = portfolioItem;
  const testimonial = testimonialsData.find(({ id }) => id === testimonialId);
  const { authorName, authorPost, description } = testimonial || {};

  return testimonial ? (
    <React.Fragment>
      <section className="payment_testimonial_area">
        <div className="container">
          <div className="row payment_testimonial_info">
            <div className="col-lg-12 d-flex align-items-center">
              <Reveal effect="fadeInUp">
                <div className="testimonial_content">
                  <div className="icon">,,</div>
                  <p className="f_p f_size_20">“{t(description)}”</p>
                  <div className="author f_600 f_p t_color f_size_20">{authorName}</div>
                  <div className="author_description f_p f_size_15">{authorPost}</div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  ) : null;
};
export default ProjectTestimonial;
