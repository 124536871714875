import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import JobApply from "../components/Careers/JobApply";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import OnepageMenu from "../components/OnepageMenu";

const JobApplyPage = props => {
  return (
    <div className="body_wrapper">
      <OnepageMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area no-full-height"
        imgName="polar-software-meeting027.jpg"
        Ptitle={props.positionData.title}
        Pdescription={props.positionData.salary}
        Pdescription2={props.positionData.cities.join(", ")}
        descriptionSize="f_size_20"
      />
      <JobApply {...props} />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default JobApplyPage;
