import React from 'react';
import ClutchWidget from '../components/ClutchWidget';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import OnepageMenu from '../components/OnepageMenu';
import { useTranslation } from 'react-i18next';
import portfolioItems from '../shared/data/portfolioItems';
import Portfolio2griditem from '../components/Portfolios/Portfolio2griditem';
import truncateString from '../shared/functions/truncateString';
import AgencyAction from '../components/AgencyAction';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const PortfolioGrid = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const chooseProjectDescription = (short, full) => (short.length === 0 ? truncateString(t(full), 180) : t(short));

  const { type: prioritizedType } = qs.parse(location.search.slice(1));

  let finalPortfolioItems = portfolioItems;
  if (prioritizedType) {
    finalPortfolioItems = [
      ...portfolioItems.filter((item) => item.projectType.includes(prioritizedType)),
      ...portfolioItems.filter((item) => !item.projectType.includes(prioritizedType)),
    ];
  }

  return (
    <div className="body_wrapper">
      <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area with_cta"
        imgName="portfolio-bg.jpg"
        Ptitle={t('OUR_WORK')}
        Pdescription={t('OUR_WORK_DESCRIPTION')}
        showCta
      />
      <div className="container">
        <div className="row portfolio_gallery mb_40 mt_50" id="work-portfolio">
          {finalPortfolioItems.map(({ link, imgs, title, shortDescription, projectType, description, id }) => (
            <Portfolio2griditem
              link={link}
              colClass="col-lg-6"
              pImg={imgs[0]}
              ptitle={title}
              tOne={projectType}
              key={id}
              description={chooseProjectDescription(shortDescription, description)}
            />
          ))}
        </div>
      </div>
      <ClutchWidget />
      <AgencyAction />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};

export default PortfolioGrid;
