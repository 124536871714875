import React, { PureComponent } from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import './i18n';
import { Helmet } from 'react-helmet';
/*------ Pages-----*/
import Service from './Pages/Service';
import Careers from './Pages/Careers';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import PortfolioGrid from './Pages/PortfolioGrid';
import PortfolioSingle from './Pages/PortfolioSingle';
import Contact from './Pages/Contact';
import ScrollToTopRoute from './ScrollToTopRoute';
import Landing from './Pages/Landing';
import JobPositionPage from './Pages/JobPosition';
import CookieBanner from 'react-cookie-banner';
import ReactGA from 'react-ga4';
import { createBrowserHistory, createLocation } from 'history';
import { jobPositionsData } from './components/Careers/JobPositionsData';
import JobApplyPage from './Pages/JobApply';
import castArray from 'lodash/castArray';
import Articles from './Pages/Articles';
import Article from './Pages/Article';
import i18n from './i18n';
import getLocalizedPath from './shared/functions/getLocalizedPath';
import { getLanguageFromPath } from './shared/functions/getLanguageFromPath';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
    this.location = createLocation();
    this.state = { path: null };
    this.history.listen((location) => {
      this.setState({ path: location.pathname + location.search });
    });
  }

  componentDidMount() {
    this.props.hideLoader();
    this.initializeReactGA();
    ReactGA.send({ hitType: 'pageview', page: `${window.location.pathname}${window.location.search}` });
    const currentPath = window.location.pathname + window.location.search;
    let languagePath = getLocalizedPath({ i18n, path: currentPath });

    if (this.location.pathname !== languagePath) {
      const pathLang = getLanguageFromPath(currentPath);
      if (pathLang) {
        setTimeout(() => i18n.changeLanguage(pathLang), 0);
      }
    }

    this.history.listen((location) => {
      ReactGA.send({ hitType: 'pageview', page: `${location.pathname}${location.search}` });
    });
    i18n.on('languageChanged', () => {
      const currentPath = window.location.pathname + window.location.search;
      let languagePath = getLocalizedPath({ i18n, path: currentPath });

      if (this.location.pathname !== languagePath) {
        this.history.replace(languagePath);
      }
    });
  }

  initializeReactGA() {
    ReactGA.initialize(GA_TRACKING_ID);
  }

  base = '/:locale';

  render() {
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang: i18n.language,
          }}
        />
        <Router history={this.history}>
          <Switch>
            {/* <Redirect from={`${this.base}/portfolio`} to={`${this.base}/portfolio-single/lvs-brokers`} /> */}
            <Redirect exact from="/" to={`/${i18n.language}`} />

            <ScrollToTopRoute exact path={this.base} component={Landing} />
            <ScrollToTopRoute path={`${this.base}/careers`} component={Careers} />
            <ScrollToTopRoute path={`${this.base}/articles/:articleLink`} component={Article} />
            <ScrollToTopRoute path={`${this.base}/articles`} component={Articles} />
            <ScrollToTopRoute path={`${this.base}/services`} component={Service} />
            <ScrollToTopRoute path={`${this.base}/contact/:subject?`} component={Contact} />
            <ScrollToTopRoute path={`${this.base}/portfolio`} component={PortfolioGrid} />
            <ScrollToTopRoute path={`${this.base}/portfolio-single/:portfolioLink`} component={PortfolioSingle} />
            {jobPositionsData.map((position) => (
              <ScrollToTopRoute
                path={castArray(position.link).map((link) => `${this.base}/${link}`)}
                exact
                component={JobPositionPage}
                componentProps={{
                  positionData: position,
                }}
              />
            ))}
            {jobPositionsData.map((position) => (
              <ScrollToTopRoute
                path={castArray(position.link).map((link) => `${this.base}/${link}/apply`)}
                exact
                component={JobApplyPage}
                componentProps={{
                  positionData: position,
                }}
              />
            ))}
            <ScrollToTopRoute path={`${this.base}/privacy-policy`} component={PrivacyPolicy} />
            <Redirect from="/not-found" to="/" />
          </Switch>
        </Router>
        <CookieBanner
          message="This website is using cookies for better user experience. Further usage means you accept it."
          onAccept={() => {}}
          cookie="user-has-accepted-cookies"
          dismissOnScroll={false}
        />
      </>
    );
  }
}

export default App;
