import React from 'react';
import Slider from 'react-slick';
import { Reveal } from 'react-reveal';
import ButtonsControl from './ButtonsControl';
import CarouselItem from './CarouselItem';
import { useTranslation } from 'react-i18next';

import './carousel.css';

const isEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  } else {
    return false;
  }
};

const Carousel = React.memo(({ items, match }) => {
  let slider;
  const { t } = useTranslation();
  const settings = {
    arrows: false,
    dots: false,
    lazyLoad: 'progressive',
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const next = () => {
    slider.slickNext();
  };

  const previous = () => {
    slider.slickPrev();
  };

  return (
    <Reveal bottom cascade>
      <div className="container carousel">
        <h5 className="carousel-title">{t('OTHER_PROJECTS')}</h5>
        <Slider ref={(el) => (slider = el)} {...settings}>
          {items.map((item) => (
            <CarouselItem item={item} match={match} key={item.id} />
          ))}
        </Slider>
        <ButtonsControl previous={previous} next={next} />
      </div>
    </Reveal>
  );
}, isEqual);

export default Carousel;
