import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Reveal } from 'react-reveal';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { withTranslation } from 'react-i18next';

class Portfolio2griditem extends Component {
  render() {
    var { link, pImg, ptitle, tOne, tTwo, description, colClass, i18n } = this.props;
    return (
      <Reveal bottom cascade>
        <div className={`portfolio_item mb-30 ${colClass} `}>
          <Link to={getLocalizedPath({ i18n, path: `/portfolio-single/${link}` })} className="portfolio_img">  
            <img src={require('../../img/portfolio-details/' + pImg)} alt="" />
            <div className="hover_content">
              <span className="img_popup leaf">
                <i className="ti-arrow-right"></i>
              </span>
            </div>
          </Link>
          <div className="item_description">
            <div className="portfolio-description">
              <div className="links">
                <span>{tOne}</span>
                <span>{tTwo}</span>
              </div>
              <Link to={getLocalizedPath({ i18n, path: `/portfolio-single/${link}` })} className="portfolio-title">
                <h3 className="f_600 f_size_20 f_p">{ptitle}</h3>
                <p>{description}</p>
              </Link>
            </div>
          </div>
        </div>
      </Reveal>
    );
  }
}

export default withTranslation()(Portfolio2griditem);
