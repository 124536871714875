import React, { Component } from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Portfolio2griditem from './Portfolio2griditem';
import Sectitle from '../Title/Sectitle';
import { withTranslation } from 'react-i18next';
import portfolioItems from '../../shared/data/portfolioItems';
import getLocalizedPath from '../../shared/functions/getLocalizedPath';
import { HashLink as Link } from 'react-router-hash-link';

class Portfolio2grid extends Component {
  state = {
    activeItem: '*',
  };

  componentDidMount() {
    var imgLoad = new ImagesLoaded('#work-portfolio');

    imgLoad.on('progress', function (instance, image) {
      this.iso = new Isotope('#work-portfolio', {
        itemSelector: '.portfolio_item',
        layoutMode: 'masonry',
      });
    });
  }
  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope('#work-portfolio', {
        itemSelector: '.portfolio_item',
        layoutMode: 'masonry',
      });
    }

    // this.iso.arrange({ filter: newFilter });
    if (newFilter === '*') {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  onActive = (v) => (v === this.state.activeItem ? 'active' : '');

  render() {
    const { t, i18n } = this.props;
    return (
      <section className="portfolio_area sec_pad bg_color" id="portfolio">
        <div className="container">
          <Sectitle
            sClass="sec_title text-center mb_70"
            Title={t('NOTABLE_PROJECTS')}
            tClass="t_color3"
            TitleP={t('NOTABLE_PROJECTS_SHORT_DESC')}
          />
          {/*<div id="portfolio_filter" className="portfolio_filter mb_50">
                        <div data-filter="*" className={`work_portfolio_item ${this.onActive('*')}`} onClick={() => { this.onFilterChange("*") }}>All</div>
                        <div data-filter="web" className={`work_portfolio_item ${this.onActive('web')}`} onClick={() => { this.onFilterChange("web") }}>Web</div>
                        <div data-filter="automotive" className={`work_portfolio_item ${this.onActive('automotive')}`} onClick={() => { this.onFilterChange("automotive") }}>Automotive</div>
                        <div data-filter="ux" className={`work_portfolio_item ${this.onActive('ux')}`} onClick={() => { this.onFilterChange("ux") }}>UI/UX</div>
                        <div data-filter="product" className={`work_portfolio_item ${this.onActive('product')}`} onClick={() => { this.onFilterChange("product") }}>Product</div>
                    </div>*/}
          <div className="row portfolio_gallery mb_30" id="work-portfolio">
            {portfolioItems.slice(0, 2).map(({ link, imgs, title, shortDescription, cssClass, id }) => (
              <Portfolio2griditem
                link={link}
                colClass={`col-lg-6 ${cssClass}`}
                pImg={imgs[0]}
                ptitle={title}
                tOne="Web"
                key={id}
                description={t(shortDescription)}
              />
            ))}
          </div>
        </div>
        <div className="col-lg-12 text-center mt_60">
          <Link to={getLocalizedPath({ i18n, path: '/portfolio' })} className="seo_btn seo_btn_one btn_hover">
            {t('SHOW_ALL_PROJECTS')}
          </Link>
        </div>
      </section>
    );
  }
}
export default withTranslation()(Portfolio2grid);
