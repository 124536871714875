import React from 'react';
import Reveal from 'react-reveal';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { calendlyLink } from '../../shared/constants/links';

const AppBanner = () => {
  const { t } = useTranslation();
  return (
    <section className="app_banner_area" id="home">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Reveal bottom cascade>
              <div className="app_banner_contentmt">
                <h2 className="f_p f_700 f_size_50 w_color mb_20 wow fadeInLeft" data-wow-delay="0.2s">
                  {t('LANDING_TITLE')}
                </h2>
                <p className="f_400 f_size_18 l_height30 w_color wow fadeInLeft" data-wow-delay="0.3s">
                  {t('LANDING_SUBTITLE')}
                </p>
                <div className="cta-links-container mt_10">
                  <Link
                    className="nav-link mt_20"
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                  >
                    <span className="btn_hover app_btn wow fadeInLeft" data-wow-delay="0.5s">
                      {t('CHECK_OUR_OFFER')}
                    </span>
                  </Link>
                  <HashLink
                    className="book_call_btn wow fadeInLeft mt_20"
                    data-wow-delay="0.5s"
                    to="#"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => window.open(calendlyLink)}
                  >
                    {t('BOOK_A_CALL')}
                  </HashLink>
                </div>
              </div>
            </Reveal>
          </div>
          <div className="col-lg-6">
            <Reveal left cascade>
              <div className="new_startup_img">
                <div className="line line_one">
                  <img src={require('../../img/new/line_01.png')} alt="" />
                </div>
                <div className="line line_two">
                  <img src={require('../../img/new/line_02.png')} alt="" />
                </div>
                <div className="line line_three">
                  <img src={require('../../img/new/line_03.png')} alt="" />
                </div>
                {/*<img src={require('../../img/home7/iPhoneX2.png')} alt="" />*/}
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppBanner;
