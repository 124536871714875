import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import OnepageMenu from "../components/OnepageMenu";

const PrivacyPolicy = () => {
  return (
    <div className="body_wrapper privacy-policy">
      <OnepageMenu
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area no-full-height"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Privacy Policy"
        Pdescription="Effective date: 2020-02-03"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>1. Introduction</h2>
              <p>
                Welcome to <b> Polar Night Software Sp. z o.o.</b>.
              </p>
              <p>
                <b>Polar Night Software Sp. z o.o.</b> (“us”, “we”, or “our”)
                operates <b>https://polarnightsoftware.com</b> (hereinafter
                referred to as <b>“Service”</b>).
              </p>
              <p>
                Our Privacy Policy governs your visit to{" "}
                <b>https://polarnightsoftware.com</b>, and explains how we
                collect, safeguard and disclose information that results from
                your use of our Service.
              </p>
              <p>
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>
              <p>
                Our Terms and Conditions (<b>“Terms”</b>) govern all use of our
                Service and together with the Privacy Policy constitutes your
                agreement with us (<b>“agreement”</b>).
              </p>
              <h2>2. Definitions</h2>
              <p>
                <b>SERVICE</b> means the https://polarnightsoftware.com website
                operated by Polar Night Software Sp. z o.o..
              </p>
              <p>
                <b>PERSONAL DATA</b> means data about a living individual who
                can be identified from those data (or from those and other
                information either in our possession or likely to come into our
                possession).
              </p>
              <p>
                <b>USAGE DATA</b> is data collected automatically either
                generated by the use of Service or from Service infrastructure
                itself (for example, the duration of a page visit).
              </p>
              <p>
                <b>COOKIES</b> are small files stored on your device (computer
                or mobile device).
              </p>
              <p>
                <b>DATA CONTROLLER</b> means a natural or legal entity who
                (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any
                personal data are, or are to be, processed. For the purpose of
                this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p>
                <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural
                or legal entity who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                in order to process your data more effectively.
              </p>{" "}
              <p>
                <b>DATA SUBJECT</b> is any living individual who is the subject
                of Personal Data.
              </p>
              <p>
                <b>THE USER</b> is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>
              <h2>3. Information Collection and Use</h2>
              <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
              </p>
              <h2>4. Types of Data Collected</h2>
              <p>
                <b>Personal Data</b>
              </p>
              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you (<b>“Personal Data”</b>). Personally
                identifiable information may include, but is not limited to:
              </p>
              <p>0.1. Email address</p>
              <p>0.2. First name and last name</p>
              <p>0.3. Phone number</p>
              <p>
                0.4. Address, Country, State, Province, ZIP/Postal code, City
              </p>
              <p>0.5. Cookies and Usage Data</p>
              <p>
                We may use your Personal Data to contact you with newsletters,
                marketing or promotional materials and other information that
                may be of interest to you. You may opt out of receiving any, or
                all, of these communications from us by following the
                unsubscribe link (if provided in subscription materials) or by
                contacting us via <b>contact@polarnightsoftware.com</b>.
              </p>
              <p>
                <b>Usage Data</b>
              </p>
              <p>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access Service by or through
                any device (<b>“Usage Data”</b>). This data is used to track
                statistics of our Service usage.
              </p>
              <p>
                This Usage Data may include information such as your computer’s
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p>
                When you access Service with a device, this Usage Data may
                include information such as the type of device you use, your
                device unique ID, the IP address of your device, your device
                operating system, the type of Internet browser you use, unique
                device identifiers and other diagnostic data.
              </p>
              <p>
                <b>Tracking Cookies Data</b>
              </p>
              <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and we hold certain information.
              </p>
              <p>
                Cookies are files with a small amount of data which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Other tracking
                technologies are also used such as beacons, tags and scripts to
                collect and track information and to improve and analyze our
                Service.
              </p>
              <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
              </p>
              <p>Examples of Cookies we use:</p>
              <p>
                0.1. <b>Session Cookies:</b> We use Session Cookies to operate
                our Service.
              </p>
              <p>
                0.2. <b>Preference Cookies:</b> We use Preference Cookies to
                remember your preferences and various settings.
              </p>
              <p>
                0.3. <b>Security Cookies:</b> We use Security Cookies for
                security purposes.
              </p>
              <p>
                0.4. <b>Advertising Cookies:</b> Advertising Cookies are used to
                serve you with advertisements that may be relevant to you and
                your interests.
              </p>
              <p>
                0.5. <b>Language Cookies:</b> Language Cookies are used to
                remember the Service language that you have decided to use.
              </p>
              <p>
                <b>Other Data</b>
              </p>
              <p>
                While using our Service, we may also collect the following
                information: sex, age, date of birth, place of birth,
                citizenship, registration at place of residence and actual
                address, telephone number (work, mobile), details of documents
                on education, qualification, professional training, employment
                agreements, non-disclosure agreements, information on bonuses
                and compensation, information on marital status, family members,
                office location and other data.
              </p>
              <h2>5. Use of Data</h2>
              <p>
                Polar Night Software Sp. z o.o. uses the collected data for
                various purposes:
              </p>
              <p>0.1. to provide and maintain our Service;</p>
              <p>0.2. to notify you about changes to our Service;</p>
              <p>
                0.3. to allow you to participate in interactive features of our
                Service when you choose to do so;
              </p>
              <p>0.4. to provide customer support;</p>
              <p>
                0.5. to gather analysis or valuable information so that we can
                improve our Service;
              </p>
              <p>0.6. to monitor the usage of our Service;</p>
              <p>0.7. to detect, prevent and address technical issues;</p>
              <p>0.8. to fulfil any other purpose for which you provide it;</p>
              <p>
                0.9. to carry out our obligations and enforce our rights arising
                from any contracts entered into between you and us, including
                for billing and collection;
              </p>
              <p>
                0.10. to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </p>
              <p>
                0.11. to provide you with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless you have opted not to receive such
                information;
              </p>
              <p>
                0.12. in any other way we may describe when you provide the
                information;
              </p>
              <p>0.13. for recruitment purposes;</p>
              <p>0.14. for any other purpose with your consent.</p>
              <h2>6. Retention of Data</h2>
              <p>
                We will retain your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period, except
                when this data is used to strengthen the security or to improve
                the functionality of our Service, or we are legally obligated to
                retain this data for longer time periods.
              </p>
              <h2>7. Transfer of Data</h2>
              <p>
                Your information, including Personal Data, may be transferred to
                – and maintained on – computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ from those of your jurisdiction.
              </p>
              <p>
                If you are located outside Poland and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to Poland and process it there.
              </p>
              <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
              </p>
              <p>
                Polar Night Software Sp. z o.o. will take all the steps
                reasonably necessary to ensure that your data is treated
                securely and in accordance with this Privacy Policy and no
                transfer of your Personal Data will take place to an
                organisation or a country unless there are adequate controls in
                place including the security of your data and other personal
                information.
              </p>
              <h2>
                8. <b>Disclosure of Data</b>
              </h2>
              <p>
                We may disclose personal information that we collect, or you
                provide:
              </p>
              <p>
                0.1. <b>Business Transaction.</b>
              </p>
              <p>
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.
              </p>
              <p>
                0.2. <b>Other cases. We may disclose your information also:</b>
              </p>
              <p>0.2.1. to our subsidiaries and affiliates;</p>
              <p>
                0.2.2. to contractors, service providers, and other third
                parties we use to support our business;
              </p>
              <p>0.2.3. to fulfill the purpose for which you provide it;</p>
              <p>
                0.2.4. for the purpose of including your company’s logo on our
                website;
              </p>
              <p>
                0.2.5. for any other purpose disclosed by us when you provide
                the information;
              </p>
              <p>0.2.6. with your consent in any other cases;</p>
              <p>
                0.2.7. if we believe disclosure is necessary or appropriate to
                protect the rights, property, or safety of the Company, our
                customers, or others.
              </p>
              <h2>9. Security of Data</h2>
              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <h2>10. Service Providers</h2>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (<b>“Service Providers”</b>), provide
                Service on our behalf, perform Service-related services or
                assist us in analysing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <h2>11. Analytics</h2>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <h2>12. CI/CD tools</h2>
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>
              <h2>13. Links to Other Sites</h2>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <h2>14. Children’s Privacy</h2>
              <p>
                Our Services are not intended for use by children under the age
                of 18 (<b>“Child”</b> or <b>“Children”</b>).
              </p>
              <p>
                We do not knowingly collect personally identifiable information
                from Children under 18. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <h2>15. Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <h2>17. Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us by email: <b>contact@polarnightsoftware.com</b>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterTwo fClass="pt_lg_150" FooterData={FooterData} />
    </div>
  );
};
export default PrivacyPolicy;
